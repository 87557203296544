import React, { useState } from 'react';
import { SearchField } from '../../../common/components/SearchField';
import { SidePanelHeader } from '../../../common/components/SidePanel';
import { registerHelpCenterEvent } from '../../../common/monitoring';
import { ITranslatorProps } from '../../../common/sgwt-i18n';
import {
  HELPCENTER_QUIT,
  HELPCENTER_STEPS,
  ISgwtHelpCenterAction,
  ISgwtHelpCenterCategory,
  ISgwtHelpCenterKnowledge,
} from '../sgwt-help-center.types';
import { getCategoryTitleById } from '../shared/utils';

interface PanelHeaderProps extends ITranslatorProps {
  categoryId?: string;
  close: () => void;
  changeExpandableState: () => void;
  expanded: boolean;
  hasMessageOnly: boolean;
  hasSearch: boolean;
  isLoading: boolean;
  knowledge: ISgwtHelpCenterKnowledge | null;
  next: (action: ISgwtHelpCenterAction) => void;
  previous: (nb?: number) => void;
  quit: HELPCENTER_QUIT;
  searchValue?: string;
  step: HELPCENTER_STEPS;
}

const getTitle = ({ translator, step, hasMessageOnly, knowledge, isLoading, categoryId }: PanelHeaderProps): string => {
  const categories: ISgwtHelpCenterCategory[] | null =
    knowledge && knowledge.categories && knowledge.categories instanceof Array ? knowledge.categories : null;
  const title = translator.translate('applicationTitle');
  if (!hasMessageOnly && isLoading) {
    return title;
  }
  if (step === HELPCENTER_STEPS.FEEDBACK) {
    return translator.translate('feedback.title');
  }
  if (hasMessageOnly || step === HELPCENTER_STEPS.FORM) {
    return translator.translate('message.openButtonLabel');
  }
  if (step === HELPCENTER_STEPS.CHAT) {
    return translator.translate('chatOpenButtonLabel');
  }
  if (
    categories &&
    categoryId &&
    (step === HELPCENTER_STEPS.CATEGORY || (step === HELPCENTER_STEPS.SEARCH && categoryId))
  ) {
    return getCategoryTitleById(categories, categoryId);
  }
  if (step === HELPCENTER_STEPS.TOPIC) {
    return translator.translate('topicHeaderTitle');
  }
  return title;
};

export const PanelHeader = (props: PanelHeaderProps) => {
  const [searchTracked, setSearchTracked] = useState(false);

  if (props.quit) {
    return <div className="panel-heading" />;
  }
  const title = getTitle(props);
  const hasBackBtn = !props.hasMessageOnly && !props.isLoading && props.step !== HELPCENTER_STEPS.HOME;

  const previous = (): void => {
    props.previous();
    registerHelpCenterEvent('header.click-back');
  };

  const onSearch = (value: string) => {
    const action: ISgwtHelpCenterAction = {
      step: HELPCENTER_STEPS.SEARCH,
      searchValue: value,
    };
    props.next(action);
    if (!searchTracked) {
      setSearchTracked(true);
      registerHelpCenterEvent('panel.search', value);
    }
  };

  return (
    <SidePanelHeader
      translator={props.translator}
      hasPreviousButton={hasBackBtn}
      onPrevious={previous}
      onClose={props.close}
      title={title}
      expanded={props.expanded}
      expandable
      changeExpandableState={props.changeExpandableState}
    >
      {!props.hasMessageOnly && props.hasSearch && (
        <div className="pt-3">
          <SearchField
            placeholder={props.translator.translate('searchPlaceholder')}
            searchValue={props.searchValue}
            onSearch={onSearch}
          />
        </div>
      )}
    </SidePanelHeader>
  );
};
