import React from 'react';
import { ITranslatorProps } from '../../../common/sgwt-i18n';
import { HELPCENTER_STEPS, ISgwtHelpCenterAction } from '../sgwt-help-center.types';

interface PanelFooterProps extends ITranslatorProps {
  expanded: boolean;
  hasFeedback: boolean;
  hasForm: boolean;
  next: (action: ISgwtHelpCenterAction) => void;
  noConsole: boolean;
}

export const PanelFooter = ({ expanded, hasFeedback, hasForm, next, noConsole, translator }: PanelFooterProps) => {
  const showChatBot = !noConsole && customElements.get('sgwt-sgbot-ms');
  const showHint = showChatBot || hasForm;
  const cols = expanded ? 'col mt-2' : 'col-12 mt-2';
  return (
    <div className="card-footer pb-3 px-4 row">
      {showHint && <p className="text-secondary mb-1 col-12">{translator.translate('message.openButtonHint')}</p>}
      {showChatBot && (
        <div className={`${cols}${expanded && (hasForm || hasFeedback) ? ' pr-1 pe-1' : ''}`}>
          <button
            className="btn btn-outline-primary btn-lg btn-block"
            type="button"
            onClick={(): void => {
              next({ step: HELPCENTER_STEPS.CHAT });
            }}
          >
            {translator.translate('chatOpenButtonLabel')}
          </button>
        </div>
      )}
      {hasFeedback && (
        <div
          className={`${cols}${expanded && showChatBot ? ' pl-1 ps-1' : ''}${
            expanded && hasFeedback ? ' pr-1 pe-1' : ''
          }`}
        >
          <button
            className="btn btn-light btn-lg btn-block"
            type="button"
            onClick={(): void => {
              next({ step: HELPCENTER_STEPS.FEEDBACK });
            }}
          >
            {translator.translate('feedback.title')}
          </button>
        </div>
      )}
      {hasForm && (
        <div className={`${cols}${expanded && (showChatBot || hasForm) ? ' pl-1 ps-1' : ''}`}>
          <button
            className="btn btn-primary btn-lg btn-block"
            type="button"
            onClick={(): void => {
              next({ step: HELPCENTER_STEPS.FORM });
            }}
          >
            {translator.translate('message.openButtonLabel')}
          </button>
        </div>
      )}
    </div>
  );
};
