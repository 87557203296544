import React from 'react';
import { flushSync } from 'react-dom';
import { WidgetConfiguration } from '@sg-widgets/shared-core';
import { BUS_GLOBAL_LANGUAGE } from '../../common/auth/bus-topics';
import { IWidgetConfigurationContext, WidgetConfigurationContext } from '../../common/configuration';
import { registerHelpCenterEvent, SgwtWidgetName, startWidgetMonitoring } from '../../common/monitoring';
import { Translator } from '../../common/sgwt-i18n';
import {
  emptyObject,
  executeWhenCustomElementIsPresent,
  isDebugForced,
  whichEnvironment,
} from '../../common/sgwt-widgets-utils';
import { widgetize, widgetPropsBoundEvent } from '../../common/widgetize';
import { ISgwtAccountCenterPublicFields } from '../sgwt-account-center/sgwt-account-center';
import HelpCenter from './components/HelpCenter';
import translator from './shared/sgwt-help-center.i18n';
import {
  HELPCENTER_QUIT,
  HELPCENTER_STEPS,
  HelpCenterFormInput,
  IHelpCenterRemoteConfiguration,
  ISgwtHelpCenterAction,
  ISgwtHelpCenterError,
  ISgwtHelpCenterKnowledge,
  ISgwtHelpCenterMessageTopic,
  ISgwtHelpCenterSearchExternalResult,
  ISgwtHelpCenterSearchResult,
  ISgwtHelpCenterUser,
} from './sgwt-help-center.types';
import {
  CommunicationType,
  doNotNotifyAgain,
  ENVIRONMENT_ENDPOINTS,
  generateCommunicationContent,
  getAsyncKnowledge,
  getCommunicationEndpoint,
  getHelpCenterConfiguration,
  getSendToByTopic,
  getValidEmail,
  ICmtFormParameters,
  IHCMessageContent,
  IHCMessageResponse,
  IUnityFormParameters,
  searchTopics,
  sendMessage,
} from './shared';

import './sgwt-help-center.scss';

function isSgwtHelpCenterEvent(
  props: Readonly<ISgwtHelpCenterDispatchProps>,
  value: string,
): value is keyof ISgwtHelpCenterDispatchProps {
  return Object.keys(props).includes(value) && typeof props[value as keyof ISgwtHelpCenterDispatchProps] === 'function';
}

export interface ISgwtHelpCenterProps extends ISgwtHelpCenterDispatchProps {
  additionalMessageInformation?: object;
  applicationId?: string;
  // TODO To decommission
  allowScreenshot: boolean;
  categoryId: string;
  chatContext: string;
  cmt?: ICmtFormParameters | null;
  communicationResponse: IHCMessageResponse | null;
  debug: boolean;
  defaultSendTo?: string;
  emailEndpoint?: string;
  errorMessage: ISgwtHelpCenterError | null;
  expanded: boolean;
  feedbackContext: string;
  handleVisible?: boolean;
  hasForm: boolean;
  history: ISgwtHelpCenterAction[];
  i18n?: any;
  impulse?: IUnityFormParameters | null;
  unity?: IUnityFormParameters | null;
  introductionTour?: boolean;
  isError: boolean;
  isLoading: boolean;
  isSending: boolean;
  knowledge?: ISgwtHelpCenterKnowledge | null;
  knowledgeUrls?: string[] | null;
  language?: string;
  mailSubject?: string;
  messageOnly?: boolean;
  messageTemplate?: string;
  messageTopics?: ISgwtHelpCenterMessageTopic[] | null;
  mode?: string;
  noConsole?: boolean;
  noImprovement?: boolean;
  openned: boolean;
  queued: ISgwtHelpCenterAction[];
  quit: HELPCENTER_QUIT;
  searchResults: ISgwtHelpCenterSearchResult[] | null;
  searchValue: string;
  selectedRating: number;
  step: HELPCENTER_STEPS;
  tags: string;
  topicId: string;
  user?: ISgwtHelpCenterUser | null;
  waitForAdditionalSearchResults: boolean;
}

interface EmptyData {
  // Nothing
}

interface ISgwtHelpCenterDispatchProps {
  onReady: (data: EmptyData) => void;
  onUpdateApplicationId: (data: { applicationId: string }) => void;
  onNextAction: (data: ISgwtHelpCenterAction) => void;
  onPreviousAction: (data: { nb: number }) => void;
  onSendMessage: (data: IHCMessageContent) => void;
  onUpdateMailSubject: (data: { mailSubject?: string }) => void;
  onOpen: (data: EmptyData) => void;
  onUpdateMessageMode: (data: { messageOnly?: boolean }) => void;
  onCategoryChange: (data: { categoryId: string | null }) => void;
  onTopicChange: (data: { topicId: string | null }) => void;
  onFormChange: (data: EmptyData) => void;
  onUpdateUser: (data: { user?: ISgwtHelpCenterUser | null }) => void;
  onUpdateI18n: (data: { i18n?: object | null }) => void;
  onClose: (data: EmptyData) => void;
  onStartIntroductionTour: (data: EmptyData) => void;
  onUpdateLanguage: (data: { language?: string }) => void;
  onSearch: (data: { value?: string | null; categoryId?: string }) => void;
  onChat: (data: EmptyData) => void;
  onUpdateIntroductionTour: (data: { introductionTour?: boolean }) => void;
  onUpdateEmailEndPoint: (data: { emailEndpoint?: string }) => void;
  onUpdateSendTo: (data: { defaultSendTo?: string }) => void;
  onUpdateMessageInfo: (data: {
    additionalMessageInformation?: {
      [key: string]: any;
    };
  }) => void;
  onUpdateMessageTopics: (data: { messageTopics?: ISgwtHelpCenterMessageTopic[] }) => void;
  onUpdateKnowledge: (data: { knowledge?: ISgwtHelpCenterKnowledge | null }) => void;
  onUpdateKnowledgeUrls: (data: { knowledgeUrls?: string[] | null }) => void;
}

interface ISgwtHelpCenterState {
  _languageSubscription: unknown;
  additionalMessageInformation?: object;
  applicationId: string | null | undefined;
  categoryId: string;
  chatContext: string;
  communicationResponse: IHCMessageResponse | null;
  defaultSendTo: string;
  emailEndpoint: string;
  errorMessage: ISgwtHelpCenterError | null;
  expanded: boolean;
  extendedSearchFunction: ExtendedSearchFunction | null;
  formValues: HelpCenterFormInput | null;
  feedbackContext: string;
  hasForm: boolean;
  hasIntroductionTour: boolean;
  hasMessageOnly: boolean;
  helpCenterConfiguration: IHelpCenterRemoteConfiguration | null;
  history: ISgwtHelpCenterAction[];
  i18n: any;
  isError: boolean;
  isLoading: boolean;
  isSending: boolean;
  knowledge: ISgwtHelpCenterKnowledge | null;
  knowledgeUrls?: string[] | null;
  language: string;
  loadingContent: boolean;
  mailSubject?: string;
  messageTopics: ISgwtHelpCenterMessageTopic[] | null;
  openned: boolean;
  queued: ISgwtHelpCenterAction[];
  quit: HELPCENTER_QUIT;
  searchResults: ISgwtHelpCenterSearchResult[] | null;
  searchValue: string;
  selectedRating: number;
  step: HELPCENTER_STEPS;
  tags: string;
  topicId: string;
  translator: Translator;
  user?: ISgwtHelpCenterUser | null;
  waitForAdditionalSearchResults: boolean;
}

type EventDetails =
  | { applicationId: string | null }
  | ISgwtHelpCenterAction
  | { nb: number }
  | { mailSubject?: string }
  | { message: IHCMessageContent & { type: CommunicationType } }
  | { messageOnly?: boolean }
  | { categoryId: string | null }
  | { topicId: string | null }
  | { user?: ISgwtHelpCenterUser | null }
  | { i18n?: object | null }
  | { emailEndpoint?: string }
  | { defaultSendTo?: string }
  | {
      additionalMessageInformation?: {
        [key: string]: any;
      };
    }
  | { messageTopics?: ISgwtHelpCenterMessageTopic[] }
  | { knowledge?: ISgwtHelpCenterKnowledge | null }
  | { knowledgeUrls?: string[] | null }
  | { value?: string | null; categoryId?: string }
  | { language?: string }
  | { introductionTour?: boolean }
  | { topic: string }
  | { categoryId?: string | null }
  | { topicId?: string | null };

export interface SgwtHelpCenterPublicFields {
  setExtendedSearchFunction: (fn: ExtendedSearchFunction | null) => void;
  open: () => void;
  feedback: (rating?: number | null) => void;
  stopRequestingFeedback: () => void;
  category: (categoryId?: string | null) => void;
  form: (defaultValues?: HelpCenterFormInput) => void;
  chat: (chatContext?: string) => void;
  topic: (topicId?: string | null) => void;
  search: (value?: string | null, categoryId?: string) => void;
  close: () => void;
  startIntroductionTour: () => void;
  setIntroductionTour: (introductionTour?: boolean) => void;
  setEmailEndpoint: (emailEndpoint?: string) => void;
  setDefaultSendTo: (defaultSendTo?: string) => void;
  setAdditionalMessageInformation: (additionalMessageInformation?: { [key: string]: any }) => void;
  setFeedbackContext: (newContext: string) => void;
  setMessageTopics: (messageTopics?: ISgwtHelpCenterMessageTopic[]) => void;
  setLanguage: (language?: string) => void;
  setI18n: (i18n?: object | null) => void;
  setUser: (user?: ISgwtHelpCenterUser | null) => void;
  setKnowledge: (knowledge?: ISgwtHelpCenterKnowledge | null, async?: boolean) => void;
  setKnowledgeUrls: (knowledgeUrls?: string[] | null) => void;
  closeRequestFeedback: () => void;
  setApplicationId: (applicationId: string | null) => void;
  setMessageOnly: (messageOnly?: boolean) => void;
  setMailSubject: (mailSubject?: string) => void;
  setTags: (tags: string | string[]) => void;
}

type ExtendedSearchFunction = (search: string) => Promise<ISgwtHelpCenterSearchExternalResult[] | null>;

const EXPANDED_PROPERTY = 'sgwt-help-center.expanded';

export class SgwtHelpCenter extends React.Component<ISgwtHelpCenterProps, ISgwtHelpCenterState> {
  static contextType = WidgetConfigurationContext;

  public static is = 'sgwt-help-center';

  public get additionalMessageInformation(): object | undefined {
    return this.state?.additionalMessageInformation;
  }
  public get applicationId(): string | null | undefined {
    return this.state?.applicationId;
  }
  public get cmt(): ICmtFormParameters | null | undefined {
    return this.props?.cmt;
  }
  public get defaultSendTo(): string | undefined {
    return this.state?.defaultSendTo;
  }
  public get emailEndpoint(): string | undefined {
    return this.state?.emailEndpoint;
  }
  public get expanded(): boolean | undefined {
    return this.state?.expanded;
  }
  public get feedbackContext(): string | undefined {
    return this.state?.feedbackContext;
  }
  public get handleVisible(): boolean | undefined {
    return this.props?.handleVisible;
  }
  public get i18n(): any {
    return this.state?.i18n;
  }
  public get impulse(): IUnityFormParameters | undefined | null {
    return this.props?.impulse;
  }
  public get unity(): IUnityFormParameters | undefined | null {
    return this.props?.unity;
  }
  public get introductionTour(): string | undefined {
    return `${this.props?.introductionTour}`;
  }
  public get knowledge(): ISgwtHelpCenterKnowledge | null | undefined {
    return this.state?.knowledge;
  }
  public get knowledgeUrls(): string[] | null | undefined {
    return this.state?.knowledgeUrls;
  }
  public get language(): string | null | undefined {
    return this.state?.language;
  }
  public get mailSubject(): string | null | undefined {
    return this.state?.mailSubject;
  }
  public get messageOnly(): string | null | undefined {
    return `${this.props?.messageOnly}`;
  }
  public get messageTemplate(): string | null | undefined {
    return this.props?.messageTemplate;
  }
  public get messageTopics(): ISgwtHelpCenterMessageTopic[] | null | undefined {
    return this.state?.messageTopics;
  }
  public get noImprovement(): boolean | null | undefined {
    return this.props?.noImprovement;
  }
  public get tags(): string | null | undefined {
    return this.state?.tags;
  }
  public get user(): ISgwtHelpCenterUser | null | undefined {
    return this.state?.user;
  }
  private debugEnabled = false;
  private widgetConfiguration: WidgetConfiguration;
  private propsBoundEvent = widgetPropsBoundEvent(SgwtHelpCenter.is);
  private onWidgetReady = (): void => {
    this.props.onReady({});
  };

  constructor(props: ISgwtHelpCenterProps, context: IWidgetConfigurationContext) {
    super(props);
    this.widgetConfiguration = context!.widgetConfiguration;
    const isExpanded = localStorage.getItem(EXPANDED_PROPERTY);
    const expanded = isExpanded === 'true';

    this.state = {
      _languageSubscription: undefined,
      additionalMessageInformation: props.additionalMessageInformation || {},
      applicationId: props.applicationId || null,
      categoryId: props.categoryId || '',
      chatContext: props.chatContext || '',
      communicationResponse: props.communicationResponse || null,
      defaultSendTo: props.defaultSendTo || '',
      emailEndpoint: props.emailEndpoint || '',
      errorMessage: props.errorMessage || null,
      expanded: expanded,
      extendedSearchFunction: null,
      feedbackContext: props.feedbackContext || '',
      formValues: null,
      hasForm: props.hasForm || false,
      hasIntroductionTour: props.introductionTour || false,
      hasMessageOnly: props.messageOnly || false,
      helpCenterConfiguration: null,
      history: props.history || [],
      i18n: props.i18n || null,
      isError: props.isError || false,
      isLoading: props.isLoading || true,
      isSending: props.isSending || false,
      knowledge: props.knowledge || null,
      knowledgeUrls: props.knowledgeUrls || null,
      language: props.language || 'EN',
      loadingContent: false,
      mailSubject: props.mailSubject,
      messageTopics: props.messageTopics || null,
      openned: props.openned || false,
      queued: props.queued || [],
      quit: props.quit || '',
      searchResults: props.searchResults || null,
      searchValue: props.searchValue || '',
      selectedRating: props.selectedRating || 0,
      step: props.step || HELPCENTER_STEPS.HOME,
      tags: props.tags || '',
      topicId: props.topicId || '',
      translator,
      user: props.user || null,
      waitForAdditionalSearchResults: props.waitForAdditionalSearchResults || false,
    };

    this.debugEnabled = this.props.debug || isDebugForced();
    if (this.state.language) {
      this.state.translator.changeCurrentLanguage(this.state.language);
    }
    if (this.props.i18n) {
      for (const lang of Object.keys(this.props.i18n)) {
        this.state.translator.addMessages(lang, this.props.i18n[lang]);
      }
    }
  }

  public componentDidMount(): void {
    this.fetchRemoteConfiguration();

    document.addEventListener(this.propsBoundEvent, this.onWidgetReady);
    startWidgetMonitoring(SgwtWidgetName.HELP_CENTER, this.props.applicationId, {
      allowScreenshot: this.props.allowScreenshot,
      customEmailEndpoint: !!this.state.emailEndpoint,
      defaultSendTo: this.props.defaultSendTo,
      handleVisible: this.props.handleVisible,
      introductionTour: this.props.introductionTour,
      language: this.props.language,
      messageOnly: this.props.messageOnly,
      mode: this.props.mode,
      noConsole: this.props.noConsole,
      tags: this.state.tags,
    });
    // prevent breaking changes with previous widget version
    for (const propName of Object.getOwnPropertyNames(this.state)) {
      const propDescriptor = Object.getOwnPropertyDescriptor(this.state, propName);
      if (propDescriptor) {
        Object.defineProperty(this, propName, propDescriptor);
      }
    }
    const subscription = this.widgetConfiguration.bus.subscribe<string>(BUS_GLOBAL_LANGUAGE, (language?: string) => {
      this.setLanguage(language);
    });
    this.setState({ ...this.state, _languageSubscription: subscription });

    if (this.props.defaultSendTo) {
      console.warn('The "default-send-to" attribute is deprecated and should not be used anymore.');
      registerHelpCenterEvent('contact-us.deprecation.default-send-to', getValidEmail(this.props.defaultSendTo));
    }
    if (typeof this.props.allowScreenshot !== 'undefined') {
      console.warn(
        'The "allow-screenshot" attribute is deprecated. The screenshot feature should be configured on the Services Admin portal directly.',
      );
      registerHelpCenterEvent('contact-us.deprecation.allow-screenshot', `${this.props.allowScreenshot}`);
    }
  }

  public componentWillUnmount(): void {
    if (this.state._languageSubscription) {
      this.widgetConfiguration.bus.unsubscribe(this.state._languageSubscription);
    }
    document.removeEventListener(this.propsBoundEvent, this.onWidgetReady);
  }

  private cancelChat = (): void => {
    this.setState({ ...this.state, chatContext: '', quit: '' });
  };

  private emitEvent = (name: string, detail: EventDetails): void => {
    const event = EVENTS_META.find((event) => event.name.endsWith(name));
    if (!event) {
      this.widgetConfiguration.log(`[DEBUG] event not found, event: ${name}`);
      return;
    }
    if (isSgwtHelpCenterEvent(this.props, event.functionName)) {
      this.props[event.functionName](detail as any);

      if (this.debugEnabled) {
        this.widgetConfiguration.log(
          `[event] '${SgwtHelpCenter.is}--${name}' - ${detail ? JSON.stringify(detail) : '{}'}`,
        );
      }
    } else {
      this.widgetConfiguration.log(
        `[DEBUG] functioName mismatches with account center props, functioName: ${event.functionName}`,
      );
    }
  };

  public setExtendedSearchFunction = (fn: ExtendedSearchFunction | null) => {
    flushSync(() => {
      this.setState({ ...this.state, extendedSearchFunction: fn });
    });
  };

  private next = (action: ISgwtHelpCenterAction): void => {
    if (emptyObject(action)) {
      return;
    }
    if (this.debugEnabled) {
      this.widgetConfiguration.log('[sgwt-help-center] Next action: ', action);
    }
    if (!this.state.openned) {
      flushSync(() => {
        this.setState({
          ...this.state,
          queued: [...this.state.queued, action],
        });
      });
      flushSync(() => {
        this.open();
      });
    } else {
      switch (action.step) {
        case HELPCENTER_STEPS.CATEGORY:
          flushSync(() => {
            this.setState({
              ...this.state,
              categoryId: action.categoryId || '',
              searchValue: '',
              topicId: '',
              searchResults: null,
            });
          });

          break;
        case HELPCENTER_STEPS.SEARCH:
          flushSync(() => {
            this.setState({
              ...this.state,
              categoryId: action.categoryId || '',
              searchValue: action.searchValue || '',
              topicId: '',
            });
          });

          if (this.state.knowledge && this.state.knowledge.topics && this.state.searchValue) {
            registerHelpCenterEvent('knowledge.search', this.state.searchValue.trim());
            const results = searchTopics(this.state.knowledge.topics, this.state.searchValue);

            flushSync(() => {
              this.setState({ ...this.state, searchResults: results });
            });

            if (this.state.extendedSearchFunction) {
              try {
                flushSync(() => {
                  this.setState({
                    ...this.state,
                    waitForAdditionalSearchResults: true,
                  });
                });

                this.state
                  .extendedSearchFunction(this.state.searchValue)
                  .then((results) => {
                    if (results) {
                      const searchResults = [
                        // In case several requests have been sent - without getting their responses - we need to
                        // remove the previous extended results, otherwise we may have duplicate items (#693).
                        ...this.state.searchResults!.filter((res) => res.item.origin !== 'extended-search'),
                        ...results.map((res: ISgwtHelpCenterSearchExternalResult) => ({
                          item: {
                            ...res,
                            id: `api-result.${Math.random()}`,
                            categoryId: '',
                            origin: 'extended-search' as const,
                          },
                          score: 0,
                        })),
                      ];
                      flushSync(() => {
                        this.setState({
                          ...this.state,
                          waitForAdditionalSearchResults: false,
                          searchResults,
                        });
                      });
                    } else {
                      flushSync(() => {
                        this.setState({
                          ...this.state,
                          waitForAdditionalSearchResults: false,
                        });
                      });
                    }
                  })
                  .catch((err) => {
                    flushSync(() => {
                      this.setState({
                        ...this.state,
                        waitForAdditionalSearchResults: false,
                      });
                    });
                    if (this.debugEnabled) {
                      this.widgetConfiguration.log(
                        `[sgwt-help-center] Search on "${this.state.searchValue.trim()}" by Extended Search Function returned an error:`,
                        err,
                      );
                    }
                  });
              } catch (err) {
                if (this.debugEnabled) {
                  this.widgetConfiguration.log(
                    `[sgwt-help-center] Search on "${this.state.searchValue.trim()}" by Extended Search Function not possible due to an error:`,
                    err,
                  );
                }
              }
            }

            if (this.debugEnabled) {
              this.widgetConfiguration.log(
                `[sgwt-help-center] For search "${this.state.searchValue.trim()}", found ${
                  this.state.searchResults?.length ?? 0
                } result(s):`,
                this.state.searchResults,
              );
            }
          } else {
            flushSync(() => {
              this.setState({ ...this.state, searchResults: null });
            });

            this.previous();

            return;
          }
          break;
        case HELPCENTER_STEPS.TOPIC:
          flushSync(() => {
            this.setState({
              ...this.state,
              categoryId: '',
              searchValue: '',
              topicId: action.topicId || '',
            });
          });
          break;
        case HELPCENTER_STEPS.FORM:
          flushSync(() => {
            this.setState({
              ...this.state,
              categoryId: '',
              searchValue: '',
              searchResults: null,
              topicId: action.topicId || '',
              formValues: action.formValues || null,
            });
          });
          break;
        case HELPCENTER_STEPS.CHAT:
          flushSync(() => {
            this.setState({
              ...this.state,
              chatContext: action.chatContext || '',
            });
          });
          break;
        case HELPCENTER_STEPS.FEEDBACK:
          flushSync(() => {
            this.setState({
              ...this.state,
              selectedRating: action.rating || 0,
            });
          });
          break;
        default:
      }
      flushSync(() => {
        this.setState({ ...this.state, step: action.step });
      });

      if (this.state.history.length > 0) {
        const lastStep: HELPCENTER_STEPS = this.state.history[this.state.history.length - 1].step;
        if (action.step === HELPCENTER_STEPS.TOPIC && lastStep === HELPCENTER_STEPS.TOPIC) {
          // Nothing to do...
        } else if (action.step === HELPCENTER_STEPS.SEARCH && lastStep === HELPCENTER_STEPS.SEARCH) {
          const newHistory = [...this.state.history];
          newHistory[newHistory.length - 1].searchValue = action.searchValue;
          flushSync(() => {
            this.setState({ ...this.state, history: newHistory });
          });
        } else {
          flushSync(() => {
            this.setState({
              ...this.state,
              history: [...this.state.history, action],
            });
          });
        }
      } else {
        flushSync(() => {
          this.setState({
            ...this.state,
            history: [...this.state.history, action],
          });
        });
      }
    }
    this.emitEvent('next', action);
  };

  private previous = (n?: number): void => {
    const nb: number = n || 1;
    this.emitEvent('previous', { nb });
    if (!this.state.quit && this.state.step === HELPCENTER_STEPS.CHAT) {
      flushSync(() => {
        this.setState({ ...this.state, quit: 'previous' });
      });
      return;
    }
    flushSync(() => {
      this.setState({ ...this.state, quit: '' });
    });
    if (this.state.history.length <= nb) {
      this.reset();
    } else {
      const previousObj: ISgwtHelpCenterAction = this.state.history[this.state.history.length - 1 - nb];

      const newHistory = [...this.state.history];
      newHistory.length = newHistory.length - 1 - nb;
      flushSync(() => {
        this.setState({ ...this.state, history: newHistory });
      });

      this.next(previousObj);
    }
  };

  private getCommunicationType = (message?: IHCMessageContent | null): CommunicationType => {
    if (!!message && 'rating' in message) {
      return 'feedback';
    }
    if (this.state.helpCenterConfiguration !== null && !!this.state.helpCenterConfiguration.communication) {
      const { medium } = this.state.helpCenterConfiguration.communication;
      return medium === 'none' ? 'mail' : medium; // TODO Should we handle none?
    }
    return 'mail';
  };

  private send = (message: IHCMessageContent, topic: string): void => {
    const type = this.getCommunicationType(message);
    this.emitEvent('send', {
      message: {
        ...message,
        type,
      },
    });

    const messageTopics: ISgwtHelpCenterMessageTopic[] | null =
      this.state.messageTopics && Array.isArray(this.state.messageTopics) ? this.state.messageTopics : null;
    const subjectSendTo: string | null = topic
      ? getSendToByTopic(messageTopics, topic, this.state.i18n, this.widgetConfiguration.environment.toLowerCase())
      : null;
    // TODO #798: `to` should not be used anymore.
    const to: string = getValidEmail(subjectSendTo ? subjectSendTo : this.state.defaultSendTo);

    // For CMT or Unity, the title of the ticket is the topic + beginning of the comment...
    let subject = this.state.mailSubject || '[SGWT Help Center] User message';
    if (type === 'cmt' || type === 'unity' || type === 'impulse') {
      subject = `${topic} - ${message.content.substring(0, 50)}${message.content.length > 50 ? '...' : ''}`;
    }
    const { content, contentWithContext } = generateCommunicationContent(
      message,
      topic,
      this.state.additionalMessageInformation,
    );

    const customEndpoint = type === 'mail' && this.state.emailEndpoint ? this.state.emailEndpoint : null;
    // TODO Why we use `defaultSendTo` here?
    const email = message.email ? message.email : getValidEmail(this.state.defaultSendTo);
    const body: IHCMessageContent = {
      ...message,
      subject,
      content:
        type === 'feedback'
          ? message.content
          : customEndpoint !== null || type === 'unity' || type === 'impulse' || type === 'cmt'
            ? contentWithContext
            : content,
      from: email,
      to,
      date: new Date().toUTCString(),
    };

    this.setState({ ...this.state, isSending: true }, () => {
      sendMessage(
        this.widgetConfiguration,
        customEndpoint || getCommunicationEndpoint(type, this.widgetConfiguration, this.state.applicationId || ''),
        body,
      )
        .then((data: any) => {
          this.setState({
            ...this.state,
            communicationResponse: { type, reponse: data },
            isSending: false,
          });
          registerHelpCenterEvent(`contact-us.${type}.success`);
        })
        .catch((error: Error): void => {
          flushSync(() => {
            this.setState({ ...this.state, isSending: false });
          });

          flushSync(() => {
            this.showError(getValidEmail(this.state.defaultSendTo), error.message);
          });

          registerHelpCenterEvent(`contact-us.${type}.error`, error.message);
        });
    });
  };

  private updateHasForm = (): ISgwtHelpCenterState => {
    if (this.state.defaultSendTo) {
      const hasForm = getValidEmail(this.state.defaultSendTo) !== '';
      return { ...this.state, hasForm };
    } else {
      // TODO we should consider when we only have the feedback form...
      const hasForm =
        this.state.applicationId !== null &&
        this.state.helpCenterConfiguration !== null &&
        !!this.state.helpCenterConfiguration.communication &&
        this.state.helpCenterConfiguration.communication.medium !== 'none';
      return { ...this.state, hasForm };
    }
  };

  private reset = (): void => {
    flushSync(() => {
      this.setState({
        ...this.state,
        ...this.updateHasForm(),
        categoryId: '',
        chatContext: '',
        errorMessage: null,
        selectedRating: 0,
        step: this.state.hasMessageOnly ? HELPCENTER_STEPS.FORM : HELPCENTER_STEPS.HOME,
        searchValue: '',
        searchResults: null,
        quit: '',
        history: [],
        communicationResponse: null,
        isLoading: !emptyObject(this.state.knowledge) ? false : true,
        isError: false,
        topicId: '',
        isSending: false,
      });
    });
  };

  private playQueued = (): void => {
    // Avoid playing the queue events if the content is currently loading...
    if (!this.state.loadingContent && this.state.queued.length > 0) {
      const queuedAction: ISgwtHelpCenterAction = this.state.queued[this.state.queued.length - 1];

      flushSync(() => {
        this.setState({ ...this.state, queued: [] });
      });
      this.next(queuedAction);
    }
  };

  private showError = (contact: string, details: string): void => {
    this.setState({
      ...this.state,
      isError: true,
      errorMessage: {
        contact,
        technicalDetails: details,
      },
    });
  };

  public open = (): void => {
    this.emitEvent('open', {});
    const noKnowledge = !this.state.knowledge || emptyObject(this.state.knowledge);
    if (!this.state.hasMessageOnly && noKnowledge) {
      if (this.state.knowledgeUrls && !emptyObject(this.state.knowledgeUrls)) {
        this.setKnowledgeUrls(this.state.knowledgeUrls);
      }
      if (this.state.applicationId) {
        this.setApplicationId(this.state.applicationId!);
      }
    }
    if (!this.state.openned) {
      this.reset();
      flushSync(() => {
        this.setState({ ...this.state, openned: true });
      });
      flushSync(() => {
        this.playQueued();
      });
      registerHelpCenterEvent('panel.open');
    }
  };

  public feedback = (rating?: number | null): void => {
    if (this.props.mode === 'b2b2c') {
      // Feedback feature is disabled for b2b2c mode.
      return;
    }
    this.next({
      step: HELPCENTER_STEPS.FEEDBACK,
      rating,
    });
  };

  public stopRequestingFeedback = () => {
    if (this.state.applicationId) {
      doNotNotifyAgain(this.widgetConfiguration, this.state.applicationId);
    }
  };

  public category = (categoryId?: string | null): void => {
    this.emitEvent('category', { categoryId });
    const action: ISgwtHelpCenterAction = {
      step: HELPCENTER_STEPS.CATEGORY,
      categoryId: categoryId,
    };
    this.next(action);
  };

  public topic = (topicId?: string | null): void => {
    this.emitEvent('topic', { topicId });
    const action: ISgwtHelpCenterAction = {
      step: HELPCENTER_STEPS.TOPIC,
      topicId: topicId,
    };
    this.next(action);
  };

  public form = (defaultValues?: HelpCenterFormInput): void => {
    this.emitEvent('form', {});
    const action: ISgwtHelpCenterAction = {
      step: HELPCENTER_STEPS.FORM,
      formValues: defaultValues,
    };
    this.next(action);
  };

  public chat = (chatContext?: string): void => {
    this.emitEvent('chat', {});
    const action: ISgwtHelpCenterAction = {
      step: HELPCENTER_STEPS.CHAT,
      chatContext: chatContext,
    };
    this.next(action);
  };

  public search = (value?: string | null, categoryId?: string): void => {
    this.emitEvent('search', { value, categoryId });
    const action: ISgwtHelpCenterAction = {
      step: HELPCENTER_STEPS.SEARCH,
      searchValue: value,
      categoryId: categoryId,
    };
    this.next(action);
  };

  public close = (): void => {
    this.emitEvent('close', {});
    if (this.state.openned) {
      if (!this.state.quit && this.state.step === HELPCENTER_STEPS.CHAT) {
        flushSync(() => {
          this.setState({ ...this.state, quit: 'close' });
        });

        return;
      }
      flushSync(() => {
        this.setState({ ...this.state, openned: false, queued: [] });
      });
      this.reset();
      registerHelpCenterEvent('panel.close');
    }
  };

  public startIntroductionTour = (): void => {
    if (this.state.hasIntroductionTour) {
      this.emitEvent('start-introduction-tour', {});
      registerHelpCenterEvent('panel.click-start-tour');
      this.close();
      if (window && window.introJs && typeof window.introJs === 'function') {
        window.introJs().start();
      }
    }
  };

  public setIntroductionTour = (introductionTour?: boolean): void => {
    this.emitEvent('update-introduction-tour', { introductionTour });
    flushSync(() => {
      this.setState({
        ...this.state,
        hasIntroductionTour: introductionTour ?? false,
      });
    });
    this.reset();
  };

  public setEmailEndpoint = (emailEndpoint?: string): void => {
    this.emitEvent('update-email-endpoint', { emailEndpoint });
    flushSync(() => {
      this.setState({ ...this.state, emailEndpoint: emailEndpoint || '' });
    });
  };

  public setDefaultSendTo = (defaultSendTo?: string): void => {
    this.emitEvent('update-default-send-to', { defaultSendTo });

    flushSync(() => {
      this.setState({
        ...this.state,
        ...this.updateHasForm(),
        defaultSendTo: defaultSendTo || '',
      });
    });
    if (defaultSendTo) {
      console.warn('The "default-send-to" attribute is deprecated and should not be used anymore.');
      registerHelpCenterEvent('contact-us.deprecation.default-send-to', getValidEmail(defaultSendTo));
    }
  };

  public setAdditionalMessageInformation = (additionalMessageInformation?: { [key: string]: any }): void => {
    this.emitEvent('update-additional-message-information', {
      additionalMessageInformation,
    });

    flushSync(() => {
      this.setState({
        ...this.state,
        additionalMessageInformation: additionalMessageInformation || {},
      });
    });
  };

  public setFeedbackContext = (newContext: string) => {
    flushSync(() => {
      this.setState({ ...this.state, feedbackContext: newContext });
    });
  };

  public setMessageTopics = (messageTopics?: ISgwtHelpCenterMessageTopic[]): void => {
    this.emitEvent('update-message-topics', { messageTopics });
    flushSync(() => {
      this.setState({ ...this.state, messageTopics: messageTopics || null });
    });
  };

  private retrieveKnowledge = (urls: string | string[] | null | undefined) => {
    if (!urls || this.state.hasMessageOnly) {
      return;
    }
    flushSync(() => {
      this.setState({ ...this.state, loadingContent: true });
    });
    getAsyncKnowledge(this.widgetConfiguration, Array.isArray(urls) ? urls : [urls])
      .then((knowledge?: ISgwtHelpCenterKnowledge): void => {
        flushSync(() => {
          this.setState({ ...this.state, loadingContent: false });
        });
        if (knowledge) {
          this.setKnowledge(knowledge, true);
        }
      })
      .catch((error) => {
        flushSync(() => {
          this.setState({
            ...this.state,
            loadingContent: false,
            isLoading: false,
          });
        });
        flushSync(() => {
          this.showError(getValidEmail(this.state.defaultSendTo), error.message);
        });
        registerHelpCenterEvent('knowledge.error.retrieve-knowledge', error.message);
      });
  };

  public setLanguage = (language?: string): void => {
    if (language) {
      this.emitEvent('update-language', { language });
      this.setState({ ...this.state, language: language || 'EN' }, () => {
        this.state.translator.changeCurrentLanguage(this.state.language);
        if (this.applicationId && this.applicationId !== 'null') {
          this.setState({ ...this.state, knowledge: null }, () => {
            this.reset();
            if (this.state.openned) {
              this.retrieveKnowledge(this.getKnowledgeUrlFromApplicationId());
            }
          });
        }
      });
    }
  };

  public setI18n = (i18n?: object | null): void => {
    this.emitEvent('update-i18n', { i18n });

    this.setState({ ...this.state, i18n: i18n || null }, () => {
      if (this.state.i18n) {
        for (const lang of Object.keys(this.state.i18n)) {
          this.state.translator.addMessages(lang, this.state.i18n[lang]);
        }
      }
    });
  };

  public setUser = (user?: ISgwtHelpCenterUser | null): void => {
    this.emitEvent('update-user', { user });
    flushSync(() => this.setState({ ...this.state, user: user || null }));
  };

  public setKnowledge = (knowledge?: ISgwtHelpCenterKnowledge | null, async?: boolean): void => {
    this.emitEvent('update-knowledge', { knowledge });
    flushSync(() => {
      this.setState({ ...this.state, knowledge: knowledge || null });
    });
    this.reset();
    flushSync(() => {
      if (async) {
        this.playQueued();
      }
    });
  };

  public setKnowledgeUrls = (knowledgeUrls?: string[] | null): void => {
    this.emitEvent('update-knowledge-urls', { knowledgeUrls });
    flushSync(() => {
      this.setState({ ...this.state, knowledge: null });
    });
    this.reset();
    this.retrieveKnowledge(knowledgeUrls);
  };

  private getKnowledgeUrlFromApplicationId = () => {
    const environment = whichEnvironment(this.widgetConfiguration);
    const api = ENVIRONMENT_ENDPOINTS['help-center-api'][environment].apiEndpoint;
    const lang = (this.state.language || 'en').toLowerCase();
    const tags = this.state.tags ? `&tags=${this.state.tags}` : '';
    return `${api}/${this.state.applicationId}?language=${lang}${tags}`;
  };

  // We call the <sgwt-account-center> to display / hide the Feedback Request Popup.
  // When it is done automatically (after API call to config), the <sgwt-account-center> may not be
  // ready yet. So we check that, and we call it only when the widget is ready.
  private callWhenAccountCenterIsReady = (
    functionToCall: string,
    cb: (accountCenter: Element & ISgwtAccountCenterPublicFields) => void,
    firstTry = true,
  ) => {
    const accountCenter = document.querySelector<ISgwtAccountCenterPublicFields & Element>('sgwt-account-center');
    if (accountCenter) {
      // <sgwt-account-center> is found. We now check if it is ready.
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!(accountCenter as any)[functionToCall]) {
        // The function is present, so we call the callback...
        if (this.debugEnabled) {
          this.widgetConfiguration.log(`[sgwt-help-center] Request Feedback ${functionToCall} called.`);
        }
        cb(accountCenter);
      } else {
        // The widget is not ready yet, so we wait for it...
        accountCenter.addEventListener('sgwt-account-center--ready', () => {
          // eslint-disable-next-line no-extra-boolean-cast
          if (!!(accountCenter as any)[functionToCall]) {
            if (this.debugEnabled) {
              this.widgetConfiguration.log(
                `[sgwt-help-center] Request Feedback ${functionToCall} when sgwt-account-center is ready.`,
              );
            }
            cb(accountCenter);
          } else {
            this.widgetConfiguration.warn(
              `The Account Center widget is found, but does not have the function "${functionToCall}".`,
            );
          }
        });
      }
    } else if (firstTry) {
      // The <sgwt-connect> widget has not been found in the document. Maybe the Help Center code is running too soon.
      // We give us a last chance to execute the code after 2s...
      setTimeout(() => {
        this.callWhenAccountCenterIsReady(functionToCall, cb, false);
      }, 2000);
    } else {
      this.widgetConfiguration.warn('Cannot call Account Center function as the widget is not found on the page.');
    }
  };

  public requestFeedback = (includeDoNotAskAgain = false) => {
    this.callWhenAccountCenterIsReady('requestFeedback', (accountCenter: Element & ISgwtAccountCenterPublicFields) => {
      accountCenter.requestFeedback(includeDoNotAskAgain);
    });
  };

  public closeRequestFeedback = () => {
    this.callWhenAccountCenterIsReady(
      'closeRequestFeedback',
      (accountCenter: Element & ISgwtAccountCenterPublicFields) => {
        accountCenter.closeRequestFeedback();
      },
    );
  };

  private fetchRemoteConfiguration = () => {
    const fetchConfig: boolean = !!this.props.applicationId && this.props.applicationId !== 'null';
    if (this.debugEnabled) {
      this.widgetConfiguration.log(
        '[configuration]',
        fetchConfig ? `Fetching configuration for ${this.props.applicationId}` : 'No applicationId available, skipped.',
      );
    }
    if (fetchConfig) {
      getHelpCenterConfiguration(this.widgetConfiguration, this.props.applicationId!)
        .then((data) => {
          if (this.debugEnabled) {
            this.widgetConfiguration.log('[remote-configuration]', JSON.stringify(data));
          }

          this.setState({ ...this.state, helpCenterConfiguration: data as any }, () => {
            if (
              !!this.state.helpCenterConfiguration?.feedback &&
              !!this.state.helpCenterConfiguration.feedback.requestFeedback
            ) {
              executeWhenCustomElementIsPresent('sgwt-account-center', { documentDefinition: true }, () => {
                this.requestFeedback(true);
              });
            }
          });

          // this.helpCenterConfiguration = data as IHelpCenterRemoteConfiguration;
          // Check if we need to request the feedback from the user...
        })
        .catch((e) => console.warn('Failed to load remote configuration', e));
    }
  };

  public setApplicationId = (applicationId: string | null): void => {
    this.emitEvent('update-application-id', { applicationId });
    flushSync(() => {
      this.setState({ ...this.state, applicationId, knowledge: null });
    });
    this.reset();
    if (applicationId && applicationId !== 'null') {
      flushSync(() => {
        this.fetchRemoteConfiguration();
      });
      flushSync(() => {
        this.retrieveKnowledge(this.getKnowledgeUrlFromApplicationId());
      });
    }
  };

  public setMailSubject = (mailSubject?: string): void => {
    this.emitEvent('update-mail-subject', { mailSubject });
    flushSync(() => {
      this.setState({ ...this.state, mailSubject });
    });
  };

  public setMessageOnly = (messageOnly?: boolean): void => {
    this.emitEvent('update-message-mode', { messageOnly });
    flushSync(() => {
      this.setState({ ...this.state, hasMessageOnly: messageOnly ?? false });
    });
    this.reset();
    if (emptyObject(this.state.knowledge) && this.state.applicationId) {
      this.retrieveKnowledge(this.getKnowledgeUrlFromApplicationId());
    }
  };

  public setTags = (tags: string | string[]) => {
    const newTags = Array.isArray(tags) ? tags.join(',') : tags;
    if (this.state.tags !== newTags) {
      flushSync(() => {
        this.setState({ ...this.state, tags: newTags, knowledge: null });
      });

      if (this.state.openned) {
        flushSync(() => {
          this.close();
        });
      }
      this.reset();
    }
  };

  private askForImprovement = () => {
    if (this.props.noImprovement) {
      return false;
    }
    const commType: CommunicationType =
      this.state.helpCenterConfiguration && this.state.helpCenterConfiguration.communication
        ? this.state.helpCenterConfiguration.communication.medium
        : 'none';
    return commType !== 'cmt' && commType !== 'unity' && commType !== 'impulse';
  };

  private changeExpandableState = () => {
    this.setState({ ...this.state, expanded: !this.state.expanded }, () => {
      if (this.state.expanded) {
        localStorage.setItem(EXPANDED_PROPERTY, 'true');
      } else {
        localStorage.removeItem(EXPANDED_PROPERTY);
      }
      registerHelpCenterEvent(`panel.view.${this.state.expanded ? 'expanded' : 'classic'}`);
    });
  };

  render() {
    const {
      cmt = null,
      handleVisible,
      impulse = null,
      unity = null,
      messageTemplate = null,
      noConsole = false,
    } = this.props;

    const {
      applicationId,
      categoryId,
      chatContext,
      communicationResponse,
      defaultSendTo,
      errorMessage,
      expanded,
      feedbackContext,
      formValues,
      hasForm,
      hasIntroductionTour,
      hasMessageOnly,
      helpCenterConfiguration,
      isError,
      isLoading,
      isSending,
      knowledge,
      language,
      messageTopics,
      openned,
      quit,
      searchResults,
      searchValue,
      selectedRating,
      step,
      topicId,
      user,
      waitForAdditionalSearchResults,
    } = this.state;

    const isAskingForImprovement = this.askForImprovement();
    const allowScreenshot = typeof this.props.allowScreenshot !== 'undefined' ? this.props.allowScreenshot : false;
    return (
      <HelpCenter
        allowScreenshot={allowScreenshot}
        applicationId={applicationId as string | undefined}
        cancelChat={this.cancelChat}
        categoryId={categoryId}
        changeExpandableState={this.changeExpandableState}
        chatContext={chatContext}
        close={this.close}
        cmtConfiguration={cmt}
        communicationResponse={communicationResponse}
        defaultSendTo={defaultSendTo}
        errorMessage={errorMessage}
        expanded={expanded}
        feedbackContext={feedbackContext}
        formValues={formValues}
        handleVisible={handleVisible}
        hasForm={hasForm}
        hasIntroductionTour={hasIntroductionTour}
        hasMessageOnly={hasMessageOnly}
        helpCenterConfiguration={helpCenterConfiguration}
        unityConfiguration={unity || impulse}
        isError={isError}
        isLoading={isLoading}
        isSending={isSending}
        knowledge={knowledge}
        language={language}
        messageTemplate={messageTemplate}
        messageTopics={messageTopics}
        mode={this.props.mode}
        next={this.next}
        noConsole={noConsole}
        noImprovement={!isAskingForImprovement}
        open={this.open}
        openned={openned}
        previous={this.previous}
        quit={quit}
        reset={this.reset}
        searchResults={searchResults}
        searchValue={searchValue}
        selectedRating={selectedRating}
        send={this.send}
        setUser={this.setUser}
        startIntroductionTour={this.startIntroductionTour}
        step={step}
        topicId={topicId}
        translator={this.state.translator}
        user={user as ISgwtHelpCenterUser | null}
        waitForAdditionalSearchResults={waitForAdditionalSearchResults}
      />
    );
  }
}

const EVENTS_META = [
  {
    name: `${SgwtHelpCenter.is}--ready`,
    functionName: 'onReady',
  },
  {
    name: `${SgwtHelpCenter.is}--update-application-id`,
    functionName: 'onUpdateApplicationId',
  },
  {
    name: `${SgwtHelpCenter.is}--next`,
    functionName: 'onNextAction',
  },
  {
    name: `${SgwtHelpCenter.is}--previous`,
    functionName: 'onPreviousAction',
  },
  {
    name: `${SgwtHelpCenter.is}--send`,
    functionName: 'onSendMessage',
  },
  {
    name: `${SgwtHelpCenter.is}--update-mail-subject`,
    functionName: 'onUpdateMailSubject',
  },
  {
    name: `${SgwtHelpCenter.is}--open`,
    functionName: 'onOpen',
  },
  {
    name: `${SgwtHelpCenter.is}--update-message-mode`,
    functionName: 'onUpdateMessageMode',
  },
  {
    name: `${SgwtHelpCenter.is}--category`,
    functionName: 'onCategoryChange',
  },
  {
    name: `${SgwtHelpCenter.is}--topic`,
    functionName: 'onTopicChange',
  },
  {
    name: `${SgwtHelpCenter.is}--form`,
    functionName: 'onFormChanged',
  },
  {
    name: `${SgwtHelpCenter.is}--update-user`,
    functionName: 'onUpdateUser',
  },
  {
    name: `${SgwtHelpCenter.is}--update-i18n`,
    functionName: 'onUpdateI18n',
  },
  {
    name: `${SgwtHelpCenter.is}--close`,
    functionName: 'onClose',
  },
  {
    name: `${SgwtHelpCenter.is}--start-introduction-tour`,
    functionName: 'onStartIntroductionTour',
  },
  {
    name: `${SgwtHelpCenter.is}--update-language`,
    functionName: 'onUpdateLanguage',
  },
  {
    name: `${SgwtHelpCenter.is}--search`,
    functionName: 'onSearch',
  },
  {
    name: `${SgwtHelpCenter.is}--chat`,
    functionName: 'onChat',
  },
  {
    name: `${SgwtHelpCenter.is}--update-introduction-tour`,
    functionName: 'onUpdateIntroductionTour',
  },
  {
    name: `${SgwtHelpCenter.is}--update-email-endpoint`,
    functionName: 'onUpdateEmailEndPoint',
  },
  {
    name: `${SgwtHelpCenter.is}--update-default-send-to`,
    functionName: 'onUpdateSendTo',
  },
  {
    name: `${SgwtHelpCenter.is}--update-additional-message-information`,
    functionName: 'onUpdateMessageInfo',
  },
  {
    name: `${SgwtHelpCenter.is}--update-message-topics`,
    functionName: 'onUpdateMessageTopics',
  },
  {
    name: `${SgwtHelpCenter.is}--update-knowledge`,
    functionName: 'onUpdateKnowledge',
  },
  {
    name: `${SgwtHelpCenter.is}--update-knowledge-urls`,
    functionName: 'onUpdateKnowledgeUrls',
  },
];

widgetize(
  SgwtHelpCenter,
  SgwtHelpCenter.is,
  {
    attributes: [
      { name: 'additional-message-information', type: 'object' },
      { name: 'application-id', type: 'string' },
      // TODO To decommission
      { name: 'allow-screenshot', type: 'boolean' },
      { name: 'category-id', type: 'string' },
      { name: 'chat-context', type: 'string' },
      { name: 'cmt', type: 'object' },
      { name: 'communication-response', type: 'object' },
      { name: 'conversation-id', type: 'string' },
      { name: 'debug', type: 'boolean' },
      { name: 'default-send-to', type: 'string' },
      { name: 'email-endpoint', type: 'string' },
      { name: 'error-message', type: 'object' },
      { name: 'expanded', type: 'boolean' },
      { name: 'feedback-context', type: 'string' },
      { name: 'handle-visible', type: 'boolean' },
      { name: 'has-form', type: 'boolean' },
      { name: 'has-message-only', type: 'boolean' },
      { name: 'history', type: 'object' },
      { name: 'i18n', type: 'object' },
      { name: 'impulse', type: 'object' },
      { name: 'introduction-tour', type: 'boolean' },
      { name: 'is-error', type: 'boolean' },
      { name: 'is-loading', type: 'boolean' },
      { name: 'is-sending', type: 'boolean' },
      { name: 'knowledge', type: 'object' },
      { name: 'knowledge-urls', type: 'object' },
      { name: 'language', type: 'string' },
      { name: 'mail-subject', type: 'string' },
      { name: 'message-only', type: 'boolean' },
      { name: 'message-template', type: 'string' },
      { name: 'message-topics', type: 'object' },
      { name: 'mode', type: 'string' },
      { name: 'no-console', type: 'boolean' },
      { name: 'no-improvement', type: 'boolean' },
      { name: 'openned', type: 'boolean' },
      { name: 'queued', type: 'object' },
      { name: 'quit', type: 'object' },
      { name: 'search-results', type: 'object' },
      { name: 'search-value', type: 'string' },
      { name: 'selected-rating', type: 'number' },
      { name: 'step', type: 'number' },
      { name: 'tags', type: 'string' },
      { name: 'topicId', type: 'string' },
      { name: 'unity', type: 'object' },
      { name: 'user', type: 'object' },
      { name: 'wait-for-additional-search-results', type: 'boolean' },
    ],
    events: EVENTS_META,
    deferredFunctions: [
      'category',
      'chart',
      'close',
      'closeRequestFeedback',
      'feedback',
      'form',
      'open',
      'requestFeedback',
      'search',
      'setAdditionalMessageInformation',
      'setApplicationId',
      'setEmailEndpoint',
      'setExtendedSearchFunction',
      'setFeedbackContext',
      'setHandleVisible',
      'setI18n',
      'setIntroductionTour',
      'setKnowledge',
      'setKnowledgeUrls',
      'setLanguage',
      'setMailSubject',
      'setMessageOnly',
      'setMessageTopics',
      'setTags',
      'setUser',
      'startIntroductionTour',
      'stopRequestingFeedback',
      'topic',
    ],
  },
  { shadow: false },
);
