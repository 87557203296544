import React, { ClassicComponent, ComponentClass, ComponentLifecycle, createRef, FunctionComponent } from 'react';
import { createRoot, Root } from 'react-dom/client';
import { WidgetConfiguration } from '@sg-widgets/shared-core';
import { WidgetConfigurationContext } from './configuration';
import { ErrorBoundary } from './ErrorBoundary';
import { checkSGWTWidgetConfiguration } from './sgwt-widget-configuration';

type Component<TProps = any, TState = any> = FunctionComponent<TProps> | ComponentClass<TProps, TState>;

type ReactInternals = 'constructor' | '_reactInternals' | '_reactInternalInstance';

type ReactField = keyof ComponentLifecycle<any, any> | keyof React.Component | keyof ClassicComponent | ReactInternals;

interface Events {
  name: string;
  functionName?: string;
  options?: {
    bubbles?: boolean;
    cancelable?: boolean;
    composed?: boolean;
  };
}
interface Setters {
  propName: string;
  propNameToSet: string;
}

type AttributeType = 'object' | 'number' | 'string' | 'boolean';

interface Attributes {
  name: string;
  type: AttributeType;
}

interface ElementProperties {
  attributes?: Attributes[];
  events?: Events[];
  setters?: Setters[];
  deferredFunctions?: string[];
}

interface WidgetizeOptions {
  shadow: boolean;
}

interface WidgetInternalState {
  attributes: Attributes[];
  component: Component;
  isConnected: boolean;
  propsBound: boolean;
  props: Record<string, unknown>;
  root: Root | null;
}

export const widgetPropsBoundEvent = (tagName: string): string => `sgwt-widget-props-bound-${tagName}`;

// Replace a kebab-case (`foo-bar`) or snake_case (`foo_bar`) to camelCase (`fooBar`).
// The web-component is working with kebab-case, but React with camelCase.
const kebabToCamel = (str: string): string =>
  str.toLowerCase().replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));

const normalizeType = (valueString: string | object | undefined, type: AttributeType): unknown => {
  if (valueString === undefined) {
    return valueString;
  }
  if (type === 'string') {
    // Force the value to be stringified, in case we receive an object.
    return String(valueString);
  }
  if (type === 'boolean') {
    return valueString === '' || String(valueString) === 'true';
  }
  if (type === 'number') {
    return Number(valueString);
  }
  if (type === 'object') {
    // In some specific cases, like with angular framework (cf. issue #835), the attribute value may be
    // an object instead of its stringified version. In such situation, we simply return the object as is.
    if (typeof valueString === 'object') {
      return valueString;
    }
    try {
      return JSON.parse(valueString);
    } catch {
      try {
        // in some cases the JSON can be sent with single quotes or HTML entitied.
        // (e.g. `{'mail': 'sgmarkets@sgcib.com'}` or `{&quot;mail&quot;: &quot;sgmarkets@sgcib.com&quot;}`).
        // This code transforms the string.
        valueString = valueString.replace(/&quot;/g, '"').replace(/'/g, '"');
        return JSON.parse(valueString);
      } catch {
        // Do nothing
      }
    }
  }
  return undefined;
};

const copyProperties = (source: object, target: object, ref: React.RefObject<Component>): void => {
  const PROPERTIES_TO_EXCLUDE: ReactField[] = [
    'componentDidCatch',
    'componentDidMount',
    'componentDidUpdate',
    'componentWillUnmount',
    'context',
    'forceUpdate',
    'getSnapshotBeforeUpdate',
    'props',
    'render',
    'setState',
    'shouldComponentUpdate',
    'constructor',
    '_reactInternals',
    '_reactInternalInstance',
    'state',
    'UNSAFE_componentWillReceiveProps',
    'UNSAFE_componentWillMount',
    'UNSAFE_componentWillUpdate',
    'componentWillReceiveProps',
    'componentWillMount',
    'componentWillUpdate',
    'refs',
    'isMounted',
    'replaceState',
  ];
  for (const propName of Object.getOwnPropertyNames(source)) {
    if (!PROPERTIES_TO_EXCLUDE.includes(propName as ReactField)) {
      const propDescriptor = Object.getOwnPropertyDescriptor(source, propName);
      if (propDescriptor) {
        // getters are accessible from the widget
        if (
          propDescriptor.set === undefined &&
          propDescriptor.get !== undefined &&
          // fix with oxg-* widgets usage (https://sgithub.fr.world.socgen/DEX/oxg-header)
          // (sgwt-account-center is rendered inside a stencil slot which calls copyProperties 2x)
          (target as any)[propName] === undefined
        ) {
          try {
            Object.defineProperty(target, propName, {
              get: propDescriptor.get?.bind(ref.current),
            });
          } catch {
            // Do nothing
          }
        }
        // functions are accessible from the widget
        const sourceAsAny = source as any;
        if (typeof sourceAsAny[propName] === 'function') {
          const targetAsAny = target as any;
          targetAsAny[propName] = sourceAsAny[propName].bind(ref.current);
        }
      }
    }
  }
};

/**
 * Transform a React component into a web-component.
 * @param component The React component.
 * @param tagName The tag name.
 * @param elementProperties The properties of the custom element (attributes, events).
 * @param options The options for the custom element creation.
 */
export function widgetize(
  component: Component,
  tagName: string,
  elementProperties: ElementProperties,
  options?: WidgetizeOptions,
) {
  // The actual custom-element class.
  const ceClass = class Widget extends HTMLElement {
    public static is = tagName;
    public static observedAttributes = elementProperties.attributes
      ? [...elementProperties.attributes.map(({ name }) => name)]
      : [];
    public static __widgetConfiguration__: WidgetConfiguration;

    public get widgetConfiguration(): WidgetConfiguration {
      return ceClass.__widgetConfiguration__;
    }

    public _internalState: WidgetInternalState;
    private _proxiedRef: React.RefObject<Component>;

    constructor() {
      super();

      if (elementProperties.deferredFunctions) {
        for (const functionName of elementProperties.deferredFunctions) {
          (this as any)[functionName] = (...args: unknown[]) => {
            return new Promise((resolve) => {
              const toBeReadyDuration = 100;
              setTimeout(() => {
                const widgetInstance = document.querySelector<any>(tagName);
                if (widgetInstance) {
                  resolve(widgetInstance[functionName](...args));
                }
              }, toBeReadyDuration);
            });
          };
        }
      }

      const atts = elementProperties.attributes ? [...elementProperties.attributes.map((attr) => ({ ...attr }))] : [];
      this._internalState = {
        attributes: atts,
        propsBound: false,
        component,
        isConnected: false,
        // `props` keeps the props for the React component. We start to put the attributes
        props: atts.reduce((obj: Record<string, unknown>, attr: Attributes) => {
          return {
            ...obj,
            [kebabToCamel(attr.name)]: undefined,
          };
        }, {}),
        root: null,
      };

      // then the events
      if (elementProperties.events) {
        for (const event of elementProperties.events) {
          const eventName = event.functionName ?? kebabToCamel(event.name);
          this._internalState.props[eventName] = (data: unknown) => {
            const evt = new CustomEvent(event.name, {
              bubbles: event.options?.bubbles,
              cancelable: event.options?.cancelable,
              composed: event.options?.composed,
              detail: data,
            });
            // Dispatch the event from the widget directly...
            this.dispatchEvent(evt);
            // ...and from the document to keep backwards compatibility with Skate.js.
            document.dispatchEvent(evt);
          };
        }
      }
      // then the setters
      if (elementProperties.setters) {
        const props = this._internalState.props;
        for (const { propName, propNameToSet } of elementProperties.setters) {
          props[kebabToCamel(propName)] = (propValue: unknown): void => {
            props[kebabToCamel(propNameToSet)] = propValue;
            setTimeout(() => this._render());
          };
        }
      }

      const ref: React.RefObject<Component> = createRef();
      this._proxiedRef = new Proxy(ref, {
        get: (target, prop) => Reflect.get(target, prop),
        set: (target, prop, value) => {
          const result = Reflect.set(target, prop, value);
          if (prop === 'current' && value) {
            copyProperties(value, this, ref);
            copyProperties(Object.getPrototypeOf(value), this, ref);
            if (!this._internalState.propsBound) {
              const readyEvent = new CustomEvent(widgetPropsBoundEvent(tagName));
              document.dispatchEvent(readyEvent);
              this._internalState.propsBound = true;
            }
          }
          return result;
        },
      });
    }

    public connectedCallback(): void {
      const renderRoot = options?.shadow ? this.attachShadow({ mode: 'open' }) : this;
      this._internalState.root = createRoot(renderRoot);
      this._internalState.isConnected = true;
      setTimeout(() => this._render());
    }

    public disconnectedCallback(): void {
      this._internalState.isConnected = false;
      if (this._internalState.root) {
        this._internalState.root.unmount();
      }
    }

    public attributeChangedCallback(name: string, oldValue: string | undefined, newValue: string | undefined): void {
      const currentAttribute = this._internalState.attributes.find((attr) => attr.name === name);
      if (oldValue !== newValue && currentAttribute) {
        this._internalState.props[kebabToCamel(name)] = normalizeType(newValue, currentAttribute.type);
        this._render();
      }
    }

    _render(): void {
      if (this._internalState.isConnected && this._internalState.root) {
        const Component = this._internalState.component;
        this._internalState.root.render(
          <React.StrictMode>
            <WidgetConfigurationContext.Provider value={{ widgetConfiguration: this.widgetConfiguration }}>
              <ErrorBoundary tagName={tagName}>
                <Component ref={this._proxiedRef} {...this._internalState.props} />
              </ErrorBoundary>
            </WidgetConfigurationContext.Provider>
          </React.StrictMode>,
        );
      }
    }
  };

  // usefull to handle frameworks (Angular, Vue...) binding properties
  const propertyDescriptors = (elementProperties.attributes ?? []).reduce<PropertyDescriptorMap>((acc, attribute) => {
    const attNameCC = kebabToCamel(attribute.name);
    acc[attribute.name] = {
      enumerable: true,
      configurable: false,
      get: function () {
        return (this as typeof ceClass.prototype)._internalState.props[attNameCC];
      },
      set: function (value) {
        const thisAsCeClass = this as typeof ceClass.prototype;
        thisAsCeClass._internalState.props[attNameCC] = normalizeType(value, attribute.type);
        setTimeout(() => thisAsCeClass._render());
      },
    };
    // Some framework, like angular, may allow developers to use camel-cased attributes.
    // The widgets should consider both syntaxes `[application-id]="appId"` and `[applicationId]="appId"`.
    // Thus, we define the property descriptor for both `application-id` and `applicationId`.
    if (attribute.name !== attNameCC) {
      acc[attNameCC] = acc[attribute.name];
    }
    return acc;
  }, {});

  Object.defineProperties(ceClass.prototype, propertyDescriptors);

  checkSGWTWidgetConfiguration();

  ceClass.__widgetConfiguration__ = new WidgetConfiguration(tagName, window.SGWTWidgetConfiguration, {
    neverUseShadowDOM: !options?.shadow,
  });

  if (tagName && !customElements.get(tagName)) {
    customElements.define(tagName, ceClass);
  }
}
