import React from 'react';
import { SvgIcon } from '../../../common/components/SvgIcon';
import { registerHelpCenterEvent } from '../../../common/monitoring';
import { ITranslatorProps } from '../../../common/sgwt-i18n';
import { isEmbeddedInIOS } from '../../../common/sgwt-widgets-utils';

interface IState {
  isHovering: boolean;
}

interface IProps extends ITranslatorProps {
  open: () => void;
}

export default class Handle extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { isHovering: false };
  }

  private open = (): void => {
    this.props.open();
    registerHelpCenterEvent('handle.open');
  };

  private mouseOver = (): void => this.setState({ isHovering: true });

  private mouseOut = (): void => this.setState({ isHovering: false });

  render() {
    const { translator } = this.props;
    const { isHovering } = this.state;
    const isTouch: boolean = 'ontouchstart' in document.documentElement || isEmbeddedInIOS();
    const tooltip: JSX.Element = (
      <div
        className="tooltip left bs-tooltip-left"
        role="tooltip"
        style={{
          position: 'relative',
          display: 'inline-block',
          opacity: '1',
        }}
      >
        <div className="arrow" style={{ top: '8px' }} />
        <div className="tooltip-inner">{translator.translate('applicationTitle')}</div>
      </div>
    );
    const mouseEvents = isEmbeddedInIOS() ? {} : { onMouseOver: this.mouseOver, onMouseOut: this.mouseOut };
    /* onMouseOver={this.mouseOver}
    onMouseOut={this.mouseOut} */
    return (
      <div className="help-center-handle">
        {isHovering && tooltip}
        <button
          type="button"
          className={`border border-right-0 border-light btn btn-primary-alt${isTouch ? ' touch' : ''} text-primary`}
          aria-label={translator.translate('applicationTitle')}
          onClick={this.open}
          {...mouseEvents}
        >
          <i className="sgwt-widgets-icon icon-sm">
            <SvgIcon type="help" aria-hidden="true" />
          </i>
        </button>
      </div>
    );
  }
}
