import React from 'react';
import { WidgetConfiguration } from '@sg-widgets/shared-core';

export interface WidgetConfigurationProps {
  widgetConfiguration: WidgetConfiguration;
}

export const WidgetConfigurationContext = React.createContext<WidgetConfigurationProps | undefined>(undefined);

export type IWidgetConfigurationContext = React.ContextType<typeof WidgetConfigurationContext>;
