import React from 'react';
import { SvgIcon } from './components/SvgIcon';
import { registerMainIssue, SgwtWidgetName } from './monitoring';

type Props = {
  tagName: string;
  children?: React.ReactNode;
};

interface State {
  error?: Error;
}

export class ErrorBoundary extends React.Component<Props, State> {
  static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  constructor(props: Props) {
    super(props);
    this.state = { error: undefined };
  }

  public render() {
    const { error } = this.state;
    if (error) {
      const { tagName } = this.props;
      console.error(`Error while rendering the widget ${tagName}:`, error);
      registerMainIssue(tagName as SgwtWidgetName, error);
      return (
        <i
          className="sgwt-widgets-icon text-warning"
          title={`The widget <${tagName}> could not be rendered. Please contact the support.`}
        >
          <SvgIcon type="error" />
        </i>
      );
    }
    return <>{this.props.children}</>;
  }
}
