import { WidgetConfiguration } from '@sg-widgets/shared-core';
import { isLoadedFromInternalProduction, whichEnvironment } from '../../../common/sgwt-widgets-utils';
import { CommunicationType } from './communication.types';

// const HOMOLOGATION_API = 'http://localhost:9999/api/v1';
const DEVELOPMENT_API = 'https://services-admin-dev.sgmarkets.world.socgen/api/v1';
const HOMOLOGATION_API = 'https://services-admin-uat.sgmarkets.world.socgen/api/v1';
const INTERNAL_PRODUCTION_API = 'https://services-admin.sgmarkets.world.socgen/api/v1';
const PRODUCTION_API = 'https://shared.sgmarkets.com/api/v1';

export const ENVIRONMENT_ENDPOINTS: any = {
  'sg-connect-v2': {
    development: {
      authenticationEndpoint: 'https://sgconnect-hom.fr.world.socgen/sgconnect',
    },
    homologation: {
      authenticationEndpoint: 'https://sgconnect-hom.fr.world.socgen/sgconnect',
    },
    production: {
      authenticationEndpoint: 'https://sso.sgmarkets.com/sgconnect',
    },
  },
  'help-center-api': {
    development: {
      apiEndpoint: `${DEVELOPMENT_API}/help-center`,
    },
    homologation: {
      apiEndpoint: `${HOMOLOGATION_API}/help-center`,
    },
    production: {
      apiEndpoint: `${PRODUCTION_API}/help-center`,
    },
  },
};

const COMMUNICATION_ENDPOINTS: {
  [key: string]: { [key: string]: string };
} = {
  mail: {
    development: `${DEVELOPMENT_API}/communication-hub/mail`,
    homologation: `${HOMOLOGATION_API}/communication-hub/mail`,
    internal_production: `${INTERNAL_PRODUCTION_API}/communication-hub/mail`,
    production: `${PRODUCTION_API}/communication-hub/mail`,
  },
  unity: {
    development: `${DEVELOPMENT_API}/communication-hub/unity`,
    homologation: `${HOMOLOGATION_API}/communication-hub/unity`,
    internal_production: `${INTERNAL_PRODUCTION_API}/communication-hub/unity`,
    production: `${PRODUCTION_API}/communication-hub/unity`,
  },
  impulse: {
    development: `${DEVELOPMENT_API}/communication-hub/impulse`,
    homologation: `${HOMOLOGATION_API}/communication-hub/impulse`,
    internal_production: `${INTERNAL_PRODUCTION_API}/communication-hub/impulse`,
    production: `${PRODUCTION_API}/communication-hub/impulse`,
  },
  cmt: {
    development: `${DEVELOPMENT_API}/communication-hub/cmt`,
    homologation: `${HOMOLOGATION_API}/communication-hub/cmt`,
    internal_production: `${INTERNAL_PRODUCTION_API}/communication-hub/cmt`,
    production: `${PRODUCTION_API}/communication-hub/cmt`,
  },
  feedback: {
    development: `${DEVELOPMENT_API}/feedback`,
    homologation: `${HOMOLOGATION_API}/feedback`,
    internal_production: `${INTERNAL_PRODUCTION_API}/feedback`,
    production: `${PRODUCTION_API}/feedback`,
  },
  none: {
    // No, but yes...
    development: `${DEVELOPMENT_API}/communication-hub/mail`,
    homologation: `${HOMOLOGATION_API}/communication-hub/mail`,
    internal_production: `${INTERNAL_PRODUCTION_API}/communication-hub/mail`,
    production: `${PRODUCTION_API}/communication-hub/mail`,
  },
};

export function getCommunicationEndpoint(
  type: CommunicationType,
  widgetConfiguration: WidgetConfiguration,
  applicationId?: string,
): string {
  let environment: string = whichEnvironment(widgetConfiguration);
  if (environment === 'production' && isLoadedFromInternalProduction('sgwt-help-center')) {
    environment = 'internal_production';
  }
  const endpoint = COMMUNICATION_ENDPOINTS[type][environment];
  return `${endpoint}${applicationId ? `?application-id=${applicationId}` : ''}`;
}
