type I18NMessage = Record<string, string>;

export class Translator {
  private messages: Record<string, I18NMessage> = {};
  private language = 'en';

  constructor(language = 'en') {
    this.language = language;
  }

  public changeCurrentLanguage(language: string): boolean {
    const lg = language.toLowerCase();
    if (this.messages[lg] !== undefined) {
      this.language = lg;
      return true;
    }
    return false;
  }

  public getCurrentLanguage() {
    return this.language;
  }

  public getAvailableLanguages() {
    return Object.keys(this.messages);
  }

  public getLanguageName(language: string) {
    return this.messages[language] && this.messages[language].id ? this.messages[language].id : language;
  }

  private flattenObject(object: any, prefix = '') {
    return Object.keys(object).reduce((accumulator: any, key: string) => {
      const pre = prefix.length ? prefix + '.' : '';
      if (typeof object[key] === 'object') {
        // Object.assign(acc, this.flattenObject(obj[k], pre + k));
        accumulator = {
          ...accumulator,
          ...this.flattenObject(object[key], pre + key),
        };
      } else {
        accumulator[pre + key] = object[key];
      }
      return accumulator;
    }, {});
  }

  public addMessages(language: string, ...listOfMessages: I18NMessage[]) {
    if (this.messages[language] === undefined) {
      this.messages[language] = {};
    }
    for (const messages of listOfMessages) {
      // We flatten the object (`{a: {b: 'x'}`} => `{'a.b': 'x'}`)
      const flattenMessages = this.flattenObject(messages);
      for (const key of Object.keys(flattenMessages)) {
        this.messages[language][key] = flattenMessages[key];
      }
    }
  }

  public translate(key: string, values?: { [key: string]: string }): string {
    let message = this.messages[this.language][key] || key;
    if (values) {
      for (const k of Object.keys(values)) {
        message = message.replace(new RegExp(`{${k}}`, 'g'), values[k]);
      }
    }
    return message;
  }
}

export interface ITranslatorProps {
  translator: Translator;
}
