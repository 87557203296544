import { ISgwtHelpCenterSearchResult, ISgwtHelpCenterTopic } from '../sgwt-help-center.types';

/**
 * Return the list of topics that contains the searched term in the title or content.
 */
export function searchTopics(topics: ISgwtHelpCenterTopic[], search: string): ISgwtHelpCenterSearchResult[] {
  const regexp = new RegExp(search.trim(), 'i');
  const matches: ISgwtHelpCenterSearchResult[] = [];
  for (const topic of topics) {
    let score = 0;
    if (regexp.test(topic.title)) {
      score = 1;
    } else if (topic.content && regexp.test(topic.content)) {
      score = 0.8;
    } else if (topic.externalLink && regexp.test(topic.externalLink)) {
      score = 0.6;
    }
    if (score > 0) {
      matches.push({
        item: { ...topic, origin: 'knowledge' },
        score,
      });
    }
  }
  return matches.sort((r1, r2) => r2.score - r1.score);
}
