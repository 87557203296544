import React, { useEffect, useState } from 'react';
import { SidePanel } from '../../../../common/components/SidePanel';
import { registerHelpCenterEvent } from '../../../../common/monitoring';
import { ITranslatorProps } from '../../../../common/sgwt-i18n';
import { emptyObject } from '../../../../common/sgwt-widgets-utils';
import {
  HELPCENTER_QUIT,
  HELPCENTER_STEPS,
  HelpCenterFormInput,
  IHelpCenterRemoteConfiguration,
  ISgwtHelpCenterAction,
  ISgwtHelpCenterKnowledge,
  ISgwtHelpCenterMessageTopic,
  ISgwtHelpCenterTopic,
  ISgwtHelpCenterUser,
  MailFormInput,
} from '../../sgwt-help-center.types';
import { IHCMessageContent } from '../../shared';
import { findIP, getTopicPropertyById, isMessageTopicIsAComplaint } from '../../shared/utils';
import { PanelHeader } from '../PanelHeader';
import {
  generateMessageContent,
  isReadyToSend,
  MessageDescription,
  NoUserPanel,
  SendButton,
  TopicSelection,
  UserInputFields,
} from './message.shared';
import { Screenshot } from './screen-capture';

interface MailFormProps extends ITranslatorProps {
  allowScreenshot: boolean;
  changeExpandableState: () => void;
  close: () => void;
  defaultSendTo: string;
  expanded: boolean;
  formValues?: HelpCenterFormInput | null;
  hasMessageOnly: boolean;
  helpCenterConfiguration: IHelpCenterRemoteConfiguration | null;
  isLoading: boolean;
  isSending: boolean;
  knowledge: ISgwtHelpCenterKnowledge | null;
  messageTemplate: string | null;
  messageTopics: ISgwtHelpCenterMessageTopic[] | null;
  next: (action: ISgwtHelpCenterAction) => void;
  previous: (nb?: number) => void;
  quit: HELPCENTER_QUIT;
  reset: () => void;
  send: (message: IHCMessageContent, topic: string) => void;
  step: HELPCENTER_STEPS;
  topicId?: string;
  topics: ISgwtHelpCenterTopic[] | null;
  user: ISgwtHelpCenterUser | null;
}

export const MailForm = (props: MailFormProps) => {
  const defaultValues: MailFormInput = (props.formValues as MailFormInput) || {};
  const [content, setContent] = useState<string>(
    defaultValues.content
      ? defaultValues.content
      : props.topicId
        ? `Topic feedback:\n"${getTopicPropertyById(props.topics, props.topicId, 'title').replace(
            /(<([^>]+)>)/gi,
            '',
          )}"\n\n`
        : props.messageTemplate || '',
  );
  const [topic, setTopic] = useState<string>(
    defaultValues.topic ? defaultValues.topic : props.topicId ? props.translator.translate('topicFeedbackLink') : '',
  );
  const [email, setEmail] = useState<string>(props.user ? props.user.email : '');
  const [username, setUsername] = useState<string>(props.user ? props.user.username : '');
  const [dirty, setDirty] = useState<boolean>(false);
  const [ipAddress, setIpAddress] = useState<string>('n/a');
  const [isValid, setIsValid] = useState<boolean>(true);
  const [screenshot, setScreenshot] = useState<string | null>(null);
  const allowScreenshot: boolean =
    props.allowScreenshot || (props.helpCenterConfiguration?.communication?.allowScreenshot ?? false);

  useEffect(() => {
    findIP.then((ipAddress) => setIpAddress(ipAddress));
  }, []);

  useEffect(() => {
    registerHelpCenterEvent('contact-us.mail-form.open');
  }, []);

  const send = () => {
    const message: IHCMessageContent = {
      ...generateMessageContent(props.user, content),
      ip: ipAddress,
      screenshot: screenshot || undefined,
    };
    if (isReadyToSend(message, topic)) {
      if (screenshot) {
        message.screenshot = screenshot;
      }
      props.send(message, topic);
    } else {
      setIsValid(false);
    }
  };

  const valueChanged = (e: any) => {
    const { value } = e.target;
    switch (e.target.name) {
      case 'topic':
        setTopic(value);
        break;
      case 'content':
        setContent(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'username':
        setUsername(value);
        break;
    }
    setDirty(true);
  };

  const screenCaptured = (screen: string | null) => {
    setScreenshot(screen);
    setDirty(true);
  };

  /**
   * We disable the "click out" feature (i.e. a click outside the SidePanel closes it) if the form
   * is dirty (i.e. the user started to fill it).
   */
  const handleClose = (fromClickOut: boolean) => {
    if (!fromClickOut || !dirty) {
      props.close();
    }
  };

  if (emptyObject(props.user)) {
    return (
      <SidePanel
        widget="sgwt-help-center"
        onClose={handleClose}
        expanded={props.expanded}
        title={props.translator.translate('panel.contact-us')}
      >
        <PanelHeader {...props} hasSearch={false} />
        <NoUserPanel defaultSendTo={props.defaultSendTo} translator={props.translator} />
        <div className="card-footer px-4 py-3">
          <button className="btn btn-primary btn-block btn-lg" onClick={props.close}>
            {props.translator.translate('message.close')}
          </button>
        </div>
      </SidePanel>
    );
  }

  const isAComplaint: boolean = isMessageTopicIsAComplaint(
    topic || '',
    props.translator.translate('message.topicMakeAComplaint'),
  );

  return (
    <SidePanel
      widget="sgwt-help-center"
      onClose={handleClose}
      expanded={props.expanded}
      title={props.translator.translate('panel.contact-us')}
    >
      <PanelHeader {...props} hasSearch={false} />
      <form className="card-body px-4 py-0">
        <UserInputFields
          isFormValid={isValid}
          onChange={valueChanged}
          translator={props.translator}
          user={props.user}
          username={username}
          email={email}
        />

        <TopicSelection
          isFormValid={isValid}
          messageTopics={props.messageTopics}
          onTopicSelection={valueChanged}
          topicId={props.topicId}
          topicSelected={topic}
          translator={props.translator}
        />

        {isAComplaint && (
          <span className="help-block d-block mb-3">
            {props.translator.translate('message.topicPlaceholderMakeAComplaintHelper')}
          </span>
        )}

        <MessageDescription
          isAComplaint={isAComplaint}
          isFormValid={isValid}
          message={content}
          onChange={valueChanged}
          translator={props.translator}
        />
        {allowScreenshot && (
          <Screenshot
            configuration={props.helpCenterConfiguration}
            onCapture={screenCaptured}
            screenshot={screenshot}
            translator={props.translator}
          />
        )}
        <SendButton translator={props.translator} onSend={send} sending={props.isSending} />
      </form>
    </SidePanel>
  );
};
