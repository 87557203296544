import React from 'react';
import { ITranslatorProps } from '../sgwt-i18n';
import { SvgIcon } from './SvgIcon';

interface RatingSelectorProps extends ITranslatorProps {
  container: 'popover' | 'form';
  isValid: boolean;
  rating?: number;
  ratingSelected: (rating: number) => void;
  starSize: number;
}

interface RatingSelectorState {
  ratingSelection: number;
}

export class RatingSelector extends React.Component<RatingSelectorProps, RatingSelectorState> {
  constructor(props: RatingSelectorProps) {
    super(props);
    this.state = {
      ratingSelection: 0,
    };
  }

  public render() {
    const filled = this.state.ratingSelection === 0 ? this.props.rating || 0 : this.state.ratingSelection;
    return (
      <div className={this.props.isValid ? '' : 'd-inline-block border-bottom border-danger'}>
        {[0, 0, 0, 0, 0].map((_e, index) => {
          const starRating = index + 1;
          const moveIn = () => this.setState({ ratingSelection: starRating });
          const moveOut = () => this.setState({ ratingSelection: 0 });
          const select = () => this.props.ratingSelected(starRating);
          return (
            <button
              className={`btn btn-link ${this.props.container === 'popover' ? 'text-black' : 'text-primary'} ps-2 pe-2`}
              key={`rating-${starRating}`}
              type="button"
              onMouseEnter={moveIn}
              onMouseLeave={moveOut}
              onClick={select}
              title={this.props.translator.translate(`feedback.rating.${starRating}`)}
              aria-label={this.props.translator.translate(`feedback.rating.${starRating}`)}
            >
              <SvgIcon
                type={starRating > filled ? 'star_outline' : 'star'}
                className="icon"
                width={this.props.starSize}
                height={this.props.starSize}
              />
            </button>
          );
        })}
      </div>
    );
  }
}
