import React from 'react';
import { WidgetConfiguration } from '@sg-widgets/shared-core';
import { ISGWTConnectOpenIDStandardClaims } from '@sgwt/connect-core/dist/src/SGWTConnectOpenIDUserInfo';
import { BUS_USER_INFO, SubscriptionHandle } from '../../../common/auth/bus-topics';
import { IWidgetConfigurationContext, WidgetConfigurationContext } from '../../../common/configuration';
import { Translator } from '../../../common/sgwt-i18n';
import { executeWhenCustomElementIsPresent, whichEnvironment } from '../../../common/sgwt-widgets-utils';
import {
  HELPCENTER_QUIT,
  HELPCENTER_STEPS,
  HelpCenterFormInput,
  IHelpCenterRemoteConfiguration,
  ISgwtHelpCenterAction,
  ISgwtHelpCenterEnvironmentInfo,
  ISgwtHelpCenterError,
  ISgwtHelpCenterKnowledge,
  ISgwtHelpCenterMessageTopic,
  ISgwtHelpCenterSearchResult,
  ISgwtHelpCenterUser,
} from '../sgwt-help-center.types';
import { authenticateWithSgConnectV2, ENVIRONMENT_ENDPOINTS } from '../shared';
import { ICmtFormParameters, IHCMessageContent, IHCMessageResponse, IUnityFormParameters } from '../shared';
import Handle from './Handle';
import { HelpCenterPanel } from './HelpCenterPanel';

interface IHelpCenterProps {
  allowScreenshot: boolean;
  applicationId?: string;
  cancelChat: () => void;
  categoryId?: string;
  changeExpandableState: () => void;
  chatContext?: string;
  close: () => void;
  cmtConfiguration: ICmtFormParameters | null;
  communicationResponse: IHCMessageResponse | null;
  defaultSendTo: string;
  errorMessage: ISgwtHelpCenterError | null;
  expanded: boolean;
  feedbackContext: string;
  formValues?: HelpCenterFormInput | null;
  handleVisible?: boolean;
  hasForm: boolean;
  hasIntroductionTour: boolean;
  hasMessageOnly: boolean;
  helpCenterConfiguration: IHelpCenterRemoteConfiguration | null;
  unityConfiguration: IUnityFormParameters | null;
  isError: boolean;
  isLoading: boolean;
  isSending: boolean;
  knowledge: ISgwtHelpCenterKnowledge | null;
  language?: string;
  messageTemplate: string | null;
  messageTopics: ISgwtHelpCenterMessageTopic[] | null;
  mode?: string;
  next: (action: ISgwtHelpCenterAction) => void;
  noConsole: boolean;
  noImprovement: boolean;
  open: () => void;
  openned: boolean;
  previous: (nb?: number) => void;
  quit: HELPCENTER_QUIT;
  reset: () => void;
  searchResults: ISgwtHelpCenterSearchResult[] | null;
  searchValue?: string;
  selectedRating: number;
  send: (message: IHCMessageContent, topic: string) => void;
  setUser: (user?: ISgwtHelpCenterUser | null) => void;
  startIntroductionTour: () => void;
  step: HELPCENTER_STEPS;
  topicId?: string;
  translator: Translator;
  user: ISgwtHelpCenterUser | null;
  waitForAdditionalSearchResults: boolean;
}

interface IHelpCenterState {
  accountCenterExists: boolean;
}

export default class HelpCenter extends React.Component<IHelpCenterProps, IHelpCenterState> {
  static contextType = WidgetConfigurationContext;
  private environmentInfo: ISgwtHelpCenterEnvironmentInfo | null = null;
  private userConnectionSubscription: SubscriptionHandle | null = null;
  private widgetConfiguration: WidgetConfiguration;

  constructor(props: IHelpCenterProps, context: IWidgetConfigurationContext) {
    super(props);
    this.widgetConfiguration = context!.widgetConfiguration;
    this.state = {
      accountCenterExists: false,
    };
  }

  private setupComponent() {
    const environment = whichEnvironment(this.widgetConfiguration);
    this.environmentInfo = ENVIRONMENT_ENDPOINTS['sg-connect-v2'][environment];
    executeWhenCustomElementIsPresent('sgwt-account-center', { documentDefinition: false }, () => {
      this.setState({ accountCenterExists: true });
    });
  }

  componentDidMount() {
    this.setupComponent();
    authenticateWithSgConnectV2(this.widgetConfiguration, this.environmentInfo).then(
      (user?: ISgwtHelpCenterUser | null): void => {
        if (user) {
          this.props.setUser(user);
        } else {
          // No user found, we listen to the bus...
          this.userConnectionSubscription = this.widgetConfiguration.bus.subscribe<ISGWTConnectOpenIDStandardClaims>(
            BUS_USER_INFO,
            (claims?: ISGWTConnectOpenIDStandardClaims) => {
              if (claims && claims.sub) {
                this.props.setUser({
                  username: claims.name,
                  email: claims.sub,
                });
              }
            },
          );
        }
      },
    );

    window.addEventListener('keyup', this.handleDocumentKeyUp);
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.handleDocumentKeyUp);
    if (this.userConnectionSubscription) {
      this.widgetConfiguration.bus.unsubscribe(this.userConnectionSubscription);
    }
  }

  handleDocumentKeyUp = (e: any): void => {
    const { openned, close } = this.props;
    if (e.keyCode === 27 && openned) {
      if (
        (e.target && e.target.nodeName && e.target.nodeName.toLowerCase() === 'input') ||
        e.target.nodeName.toLowerCase() === 'select' ||
        e.target.nodeName.toLowerCase() === 'textarea'
      ) {
        return;
      }
      close();
    }
  };

  render() {
    const showHandle = this.props.handleVisible ? this.props.handleVisible : !this.state.accountCenterExists;
    return (
      <div className="sgwt-help-center">
        {this.props.openned ? (
          <HelpCenterPanel {...this.props} environment={this.widgetConfiguration.environment} />
        ) : showHandle ? (
          <Handle {...this.props} />
        ) : null}
      </div>
    );
  }
}
