export type CommunicationType = 'none' | 'mail' | 'impulse' | 'unity' | 'cmt' | 'feedback';

// TODO Define type from array?
export type Value_012 = '0' | '1' | '2';
export type Value_12 = '1' | '2';
export type Value_123 = '1' | '2' | '3';
export type UnityType = 'incident' | 'request';
export type UnityImpactedEnvironment = 'prod' | 'pre-prod' | 'homo' | 'test' | 'dev';
export const values012: Value_012[] = ['0', '1', '2'];
export const values12: Value_12[] = ['1', '2'];
export const values123: Value_123[] = ['1', '2', '3'];
export const unityTypeValues: UnityType[] = ['incident', 'request'];
export const unityImpactedEnvironmentValues: UnityImpactedEnvironment[] = ['prod', 'pre-prod', 'homo', 'test', 'dev'];

export const UNITY_IMPACTED_ENVIRONMENT_LABELS: { [key in UnityImpactedEnvironment]: string } = {
  prod: 'Production',
  'pre-prod': 'Pre-production',
  homo: 'Homologation',
  test: 'Test',
  dev: 'Development',
};

export interface IHCMessageResponse {
  type: CommunicationType;
  reponse?: ICmtApiResponse | IUnityApiResponse;
}

// Default content of a message sent from the Help Center.
export interface IHCMessageContent {
  content: string;
  date: string;
  email: string;
  from: string; // same as email, but kept for backward compatibility with customEndpoint old signature...
  to?: string; // for email content
  ip?: string;
  screenshot?: string;
  subject: string;
  url?: string;
  userAgent?: string;
  username: string;
}

// ----------------------------------------------------------------------------
//                          UNITY / IMPULSE
// ----------------------------------------------------------------------------

// Parameters for the Unity form on Widget side
export interface IUnityFormParameters {
  assignmentGroup: string[];
  caseType: UnityType[] | UnityType;
  cmdbCi: string[];
  impact: Value_123[] | Value_123;
  impactedEnvironment: UnityImpactedEnvironment[];
  peopleToNotify: string;
  symptomCategory: string[];
  urgency: Value_123[] | Value_123;
  username: string;
}

// Content of the Unity message sent to the API
export interface IHCUnityContent extends IHCMessageContent {
  assignmentGroup: string;
  caseType: UnityType;
  cmdbCi: string;
  impact: Value_123; // 1: High => 3: Low
  impactedEnvironment: string;
  peopleToNotify: string;
  symptomCategory: string;
  urgency: Value_123; // 1: High => 3: Low
  whyHighImpact?: string;
  screenshot?: string;
}

// Unity API response
export interface IUnityApiResponse {
  id: string;
  sysid: string;
  status: string;
  message: string;
}

// ----------------------------------------------------------------------------
//                             CMT
// ----------------------------------------------------------------------------
// Parameters for the CMT form, on Widget side
export interface ICmtFormParameters {
  bdrId?: string; // TODO Should be set on widget configuration for internal client.
  bdrLevel?: string; // TODO Should be set on widget configuration for internal client.
  caseType: Value_012[] | Value_012;
  casePriority: Value_012[] | Value_012;
  caseCategory: string;
  caseSubCategory: string;
  toWorkBasket: string;
  toWorkGroup: string;
}

// Content of the CMT message sent to the API
export interface IHCCmtContent extends IHCMessageContent {
  bdrId?: string;
  bdrLevel?: string;
  caseCategory: string;
  casePriority: Value_012;
  caseSubCategory: string;
  caseType: Value_012;
  toWorkBasket: string;
  toWorkGroup: string;
}

// CMT API response
export interface ICmtApiResponse {
  status: string;
  ID: string;
}

// ----------------------------------------------------------------------------
//                             FEEDBACK
// ----------------------------------------------------------------------------
export interface IHCFeedbackContent extends IHCMessageContent {
  rating: number;
}
