/* eslint-disable @typescript-eslint/no-var-requires */
import { Translator } from '../../../common/sgwt-i18n';

const de = require('../locales/de.json');
const en = require('../locales/en.json');
const es = require('../locales/es.json');
const fr = require('../locales/fr.json');
const it = require('../locales/it.json');
const ja = require('../locales/ja.json');
const pt = require('../locales/pt.json');
const ru = require('../locales/ru.json');
const zh = require('../locales/zh.json');

const sharedDE = require('../../../common/locales/de.json');
const sharedEN = require('../../../common/locales/en.json');
const sharedES = require('../../../common/locales/es.json');
const sharedFR = require('../../../common/locales/fr.json');
const sharedIT = require('../../../common/locales/it.json');
const sharedJA = require('../../../common/locales/ja.json');
const sharedPT = require('../../../common/locales/pt.json');
const sharedRU = require('../../../common/locales/ru.json');
const sharedZH = require('../../../common/locales/zh.json');

const translator = new Translator();
translator.addMessages('de', de, sharedDE);
translator.addMessages('en', en, sharedEN);
translator.addMessages('es', es, sharedES);
translator.addMessages('fr', fr, sharedFR);
translator.addMessages('it', it, sharedIT);
translator.addMessages('ja', ja, sharedJA);
translator.addMessages('jp', ja, sharedJA);
translator.addMessages('pt', pt, sharedPT);
translator.addMessages('ru', ru, sharedRU);
translator.addMessages('zh', zh, sharedZH);

export default translator;
