import React from 'react';
import { SidePanel } from '../../../../common/components/SidePanel';
import { registerHelpCenterEvent } from '../../../../common/monitoring';
import { ITranslatorProps } from '../../../../common/sgwt-i18n';
import { emptyObject } from '../../../../common/sgwt-widgets-utils';
import {
  HELPCENTER_QUIT,
  HELPCENTER_STEPS,
  IHelpCenterRemoteConfiguration,
  ISgwtHelpCenterAction,
  ISgwtHelpCenterKnowledge,
  ISgwtHelpCenterMessageTopic,
  ISgwtHelpCenterTopic,
  ISgwtHelpCenterUser,
} from '../../sgwt-help-center.types';
import { ICmtFormParameters, IHCCmtContent, IHCMessageContent, Value_012, values012 } from '../../shared';
import { findIP, isMessageTopicIsAComplaint } from '../../shared/utils';
import { PanelHeader } from '../PanelHeader';
import {
  generateMessageContent,
  getChoicesForField,
  getFieldDefaultValue,
  hasChoiceForField,
  isReadyToSend,
  MessageDescription,
  NoUserPanel,
  SendButton,
  TopicSelection,
  UserFixedField,
} from './message.shared';

interface ICmtFormState {
  dirty: boolean;
  isValid: boolean;
  ipAddress: string;
  topic: string;
  content: string;
  cmtData: {
    caseType: Value_012;
    casePriority: Value_012;
  };
}

interface ICmtFormProps extends ITranslatorProps {
  changeExpandableState: () => void;
  close: () => void;
  cmtConfiguration: ICmtFormParameters;
  defaultSendTo: string;
  environment: string;
  expanded: boolean;
  hasMessageOnly: boolean;
  helpCenterConfiguration: IHelpCenterRemoteConfiguration | null;
  isLoading: boolean;
  isSending: boolean;
  knowledge: ISgwtHelpCenterKnowledge | null;
  messageTemplate: string | null;
  messageTopics: ISgwtHelpCenterMessageTopic[] | null;
  next: (action: ISgwtHelpCenterAction) => void;
  previous: (nb?: number) => void;
  quit: HELPCENTER_QUIT;
  reset: () => void;
  send: (message: IHCMessageContent, topic: string) => void;
  step: HELPCENTER_STEPS;
  topicId?: string;
  topics: ISgwtHelpCenterTopic[] | null;
  user: ISgwtHelpCenterUser | null;
}

export class CmtForm extends React.Component<ICmtFormProps, ICmtFormState> {
  constructor(props: ICmtFormProps) {
    super(props);
    this.state = {
      dirty: false,
      isValid: true,
      ipAddress: 'n/a',
      topic: props.topicId ? props.translator.translate('topicFeedbackLink') : '',
      content: props.messageTemplate || '',
      cmtData: {
        caseType: getFieldDefaultValue(this.getChoices<Value_012>('caseType', values012), '2'),
        casePriority: getFieldDefaultValue(this.getChoices<Value_012>('casePriority', values012), '2'),
      },
    };
    findIP.then((ip) => this.setState({ ipAddress: ip }));
  }

  private getChoices<T>(field: string, allowedValues: T[]): T[] | null {
    const choices = getChoicesForField(this.props.cmtConfiguration, this.props.helpCenterConfiguration, 'cmt', field);
    if (choices === null) {
      return null;
    }
    return (choices as any[]).filter((c) => allowedValues.indexOf(c) > -1);
  }

  private send = (): void => {
    const { cmtData, topic } = this.state;
    const message: IHCCmtContent = {
      ...generateMessageContent(this.props.user, this.state.content),
      ...this.props.cmtConfiguration,
      ...cmtData,
      ip: this.state.ipAddress,
    };
    const ready = isReadyToSend(message, topic);
    if (ready) {
      this.props.send(message, topic);
    } else {
      this.setState({ isValid: false });
    }
  };

  private valueChanged = (e: any) => {
    const newState = { ...this.state, dirty: true };
    (newState as any)[e.target.name] = e.target.value;
    this.setState(newState);
  };

  private cmtValueChanged = (e: any) => {
    const newState = {
      ...this.state,
      dirty: true,
    };
    (newState as any).cmtData[e.target.name] = e.target.value;
    this.setState(newState);
  };

  componentDidMount() {
    registerHelpCenterEvent('contact-us.cmt-form');
  }

  /**
   * We disable the "click out" feature (i.e. a click outside the SidePanel closes it) if the form
   * is dirty (i.e. the user started to fill it).
   */
  private handleClose = (fromClickOut: boolean) => {
    if (!fromClickOut || !this.state.dirty) {
      this.props.close();
    }
  };

  render() {
    const { defaultSendTo, topicId, messageTopics, isSending, translator, user } = this.props;
    const { content, cmtData, isValid, topic } = this.state;

    if (emptyObject(user)) {
      return (
        <SidePanel
          widget="sgwt-help-center"
          onClose={this.handleClose}
          expanded={this.props.expanded}
          title={this.props.translator.translate('panel.contact-us')}
        >
          <PanelHeader {...this.props} hasSearch={false} />
          <NoUserPanel defaultSendTo={defaultSendTo} translator={translator} />
          <div className="card-footer px-4 py-3">
            <button className="btn btn-primary btn-block btn-lg" onClick={this.props.close}>
              {translator.translate('message.close')}
            </button>
          </div>
        </SidePanel>
      );
    }
    const isAComplaint: boolean = isMessageTopicIsAComplaint(
      topic || '',
      translator.translate('message.topicMakeAComplaint'),
    );

    // Get possibles choices from remote configuration & widget configuration...
    const typeChoices = this.getChoices('caseType', values012);
    const typeDisabled = !hasChoiceForField(this.getChoices('caseType', values012));

    const priorityChoices = this.getChoices('casePriority', values012);
    const priorityHidden = !hasChoiceForField(priorityChoices);
    const prioritySelected = cmtData.casePriority;

    return (
      <SidePanel
        widget="sgwt-help-center"
        onClose={this.handleClose}
        expanded={this.props.expanded}
        title={this.props.translator.translate('panel.contact-us')}
      >
        <PanelHeader {...this.props} hasSearch={false} />
        <form className="card-body px-4 pb-4 pt-0 d-flex flex-column">
          <UserFixedField translator={translator} user={user!} />

          <TopicSelection
            isFormValid={this.state.isValid}
            messageTopics={messageTopics}
            onTopicSelection={this.valueChanged}
            topicId={topicId}
            topicSelected={topic}
            translator={translator}
          />

          {isAComplaint && (
            <span className="help-block d-block mb-3">
              {translator.translate('message.topicPlaceholderMakeAComplaintHelper')}
            </span>
          )}

          {/* CASE TYPE - Request or Incident */}
          <div className="form-group mb-3">
            <label htmlFor="caseType" className="mb-2">
              {translator.translate('cmt.caseType')}
            </label>
            <select
              className="form-control form-select"
              id="caseType"
              name="caseType"
              onChange={this.cmtValueChanged}
              disabled={typeDisabled}
              value={cmtData.caseType}
            >
              {['0', '1', '2'].map((val: string) => {
                if (typeChoices !== null && typeChoices.indexOf(val as Value_012) === -1) {
                  return null;
                }
                return (
                  <option key={`case-type-${val}`} value={val}>
                    {translator.translate(`cmt.caseType.${val}`)}
                  </option>
                );
              })}
            </select>
          </div>

          <MessageDescription
            translator={translator}
            isAComplaint={isAComplaint}
            isFormValid={isValid}
            message={content}
            onChange={this.valueChanged}
          />

          {/* PRIORITY LEVEL */}
          {!priorityHidden && (
            <div className="form-group">
              <label htmlFor="casePriority" className="mb-2">
                {translator.translate('cmt.casePriority')}
              </label>
              <div
                className="btn-group btn-group-toggle w-100 mb-3"
                data-toggle="buttons"
                role="group"
                aria-label="toggle buttons single select"
              >
                {['0', '1', '2'].map((val: string) => {
                  if (priorityChoices !== null && priorityChoices.indexOf(val as Value_012) === -1) {
                    return null;
                  }
                  return (
                    <label
                      key={`casePriority-${val}`}
                      className={`btn btn-toggle-primary border ${prioritySelected === val ? 'active' : ''}`}
                    >
                      <input
                        type="radio"
                        className="btn-check"
                        name="casePriority"
                        onClick={this.cmtValueChanged}
                        value={val}
                      />
                      {translator.translate(`cmt.casePriority.${val}`)}
                    </label>
                  );
                })}
              </div>
            </div>
          )}
          <SendButton translator={translator} onSend={this.send} sending={isSending} />
        </form>
      </SidePanel>
    );
  }
}
