import React from 'react';
import { ITranslatorProps } from '../../../../common/sgwt-i18n';
import {
  HELPCENTER_STEPS,
  ISgwtHelpCenterAction,
  ISgwtHelpCenterCategory,
  ISgwtHelpCenterKnowledge,
  ISgwtHelpCenterSearchResult,
  ISgwtHelpCenterTopic,
} from '../../sgwt-help-center.types';
import { Categories } from './Categories';
import { Topic } from './Topic';
import { Topics } from './Topics';

interface IKnowledgeBodyProps extends ITranslatorProps {
  categoryId?: string;
  close: () => void;
  expanded: boolean;
  hasForm: boolean;
  hasIntroductionTour: boolean;
  isLoading: boolean;
  knowledge: ISgwtHelpCenterKnowledge | null;
  next: (action: ISgwtHelpCenterAction) => void;
  noImprovement: boolean;
  searchResults: ISgwtHelpCenterSearchResult[] | null;
  searchValue?: string;
  startIntroductionTour: () => void;
  step: HELPCENTER_STEPS;
  topicId?: string;
  waitForAdditionalSearchResults: boolean;
}

export class KnowledgeBody extends React.Component<IKnowledgeBodyProps> {
  private _refs: { el: any } = { el: null };

  public componentDidMount() {
    this._refs.el?.scrollTop(0);
  }

  render() {
    const {
      categoryId,
      close,
      expanded = false,
      hasForm = false,
      hasIntroductionTour = false,
      knowledge = null,
      next,
      noImprovement = false,
      searchResults = null,
      searchValue,
      startIntroductionTour,
      step = HELPCENTER_STEPS.HOME,
      topicId,
      translator,
      waitForAdditionalSearchResults,
    } = this.props;
    const categories: ISgwtHelpCenterCategory[] | null =
      knowledge && knowledge.categories && knowledge.categories instanceof Array ? knowledge.categories : null;
    const topics: ISgwtHelpCenterTopic[] | null =
      knowledge && knowledge.topics && knowledge.topics instanceof Array ? knowledge.topics : null;

    return (
      <div className="card-body px-4 py-0 mb-4">
        {step === HELPCENTER_STEPS.TOPIC && (
          <Topic
            hasForm={hasForm}
            next={next}
            noImprovement={noImprovement}
            searchResults={searchResults}
            topicId={topicId}
            topics={topics}
            translator={translator}
          />
        )}
        {(step === HELPCENTER_STEPS.HOME || step === HELPCENTER_STEPS.CATEGORY) && (
          <Categories
            categories={categories}
            categoryId={categoryId}
            expanded={expanded}
            next={next}
            step={step}
            translator={translator}
          />
        )}
        {(step === HELPCENTER_STEPS.HOME || step === HELPCENTER_STEPS.CATEGORY || step === HELPCENTER_STEPS.SEARCH) && (
          <Topics
            categoryId={categoryId}
            close={close}
            hasIntroductionTour={hasIntroductionTour}
            next={next}
            searchResults={searchResults}
            searchValue={searchValue}
            startIntroductionTour={startIntroductionTour}
            step={step}
            topics={topics}
            translator={translator}
            waitForAdditionalSearchResults={waitForAdditionalSearchResults}
          />
        )}
      </div>
    );
  }
}
