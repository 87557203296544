import React from 'react';
import { SidePanel } from '../../../common/components/SidePanel';
import { Translator } from '../../../common/sgwt-i18n';
import {
  HELPCENTER_QUIT,
  HELPCENTER_STEPS,
  HelpCenterFormInput,
  IHelpCenterRemoteConfiguration,
  ISgwtHelpCenterAction,
  ISgwtHelpCenterKnowledge,
  ISgwtHelpCenterMessageTopic,
  ISgwtHelpCenterTopic,
  ISgwtHelpCenterUser,
} from '../sgwt-help-center.types';
import { ICmtFormParameters, IHCMessageContent, isCommunicationOfType, IUnityFormParameters } from '../shared';
import Chat from './form/Chat';
import { CmtForm, FeedbackForm, MailForm, UnityForm } from './form';
import { PanelHeader } from './PanelHeader';

interface IFormPanelProps {
  allowScreenshot: boolean;
  applicationId?: string;
  cancelChat: () => void;
  changeExpandableState: () => void;
  chatContext?: string;
  close: () => void;
  cmtConfiguration: ICmtFormParameters | null;
  defaultSendTo: string;
  environment: string;
  expanded: boolean;
  feedbackContext: string;
  formValues?: HelpCenterFormInput | null;
  hasForm: boolean;
  hasMessageOnly: boolean;
  helpCenterConfiguration: IHelpCenterRemoteConfiguration | null;
  unityConfiguration: IUnityFormParameters | null;
  isLoading: boolean;
  isSending: boolean;
  knowledge: ISgwtHelpCenterKnowledge | null;
  messageTemplate: string | null;
  messageTopics: ISgwtHelpCenterMessageTopic[] | null;
  next: (action: ISgwtHelpCenterAction) => void;
  noConsole: boolean;
  previous: (nb?: number) => void;
  quit: HELPCENTER_QUIT;
  reset: () => void;
  selectedRating: number;
  send: (message: IHCMessageContent, topic: string) => void;
  step: HELPCENTER_STEPS;
  topicId?: string;
  translator: Translator;
  user: ISgwtHelpCenterUser | null;
}

export const FormPanel = (props: IFormPanelProps) => {
  const { helpCenterConfiguration, knowledge, step } = props;
  if (step === HELPCENTER_STEPS.CHAT) {
    if (customElements.get('sgwt-sgbot-ms')) {
      return (
        <SidePanel
          widget="sgwt-help-center"
          onClose={props.close}
          expanded={props.expanded}
          title={props.translator.translate('panel.bot')}
        >
          <PanelHeader {...props} hasSearch={false} />
          <div className="card-body pt-0">
            <Chat {...props} />
          </div>
        </SidePanel>
      );
    } else {
      console.error('sgwt-sgbot-ms is not available');
      return null;
    }
  }
  if (step === HELPCENTER_STEPS.FEEDBACK) {
    return <FeedbackForm {...props} />;
  }

  const topics: ISgwtHelpCenterTopic[] | null =
    knowledge && knowledge.topics && knowledge.topics instanceof Array ? knowledge.topics : null;

  const isUnity =
    isCommunicationOfType(helpCenterConfiguration, 'unity') ||
    isCommunicationOfType(helpCenterConfiguration, 'impulse');

  return isUnity ? (
    <UnityForm {...props} topics={topics} unityConfiguration={props.unityConfiguration!} />
  ) : isCommunicationOfType(helpCenterConfiguration, 'cmt') ? (
    <CmtForm {...props} topics={topics} cmtConfiguration={props.cmtConfiguration!} />
  ) : (
    <MailForm {...props} topics={topics} />
  );
};
