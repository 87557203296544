import React from 'react';
import { RatingSelector } from '../../../../common/components/RatingSelector';
import { SidePanel } from '../../../../common/components/SidePanel';
import { SvgIcon } from '../../../../common/components/SvgIcon';
import { registerHelpCenterEvent } from '../../../../common/monitoring';
import { ITranslatorProps } from '../../../../common/sgwt-i18n';
import {
  HELPCENTER_QUIT,
  HELPCENTER_STEPS,
  ISgwtHelpCenterAction,
  ISgwtHelpCenterKnowledge,
  ISgwtHelpCenterUser,
} from '../../sgwt-help-center.types';
import { IHCFeedbackContent, IHCMessageContent } from '../../shared';
import { findIP } from '../../shared/utils';
import { PanelHeader } from '../PanelHeader';
import { generateMessageContent, isReadyToSend, SendButton } from './message.shared';

interface IFeedbackState {
  btnDisabled: boolean;
  contactMe: boolean;
  content: string;
  dirty: boolean;
  ipAddress: string;
  isValid: boolean;
  rating: number;
}

interface IFeedbackProps extends ITranslatorProps {
  applicationId?: string;
  changeExpandableState: () => void;
  close: () => void;
  expanded: boolean;
  feedbackContext: string;
  hasMessageOnly: boolean;
  isLoading: boolean;
  isSending: boolean;
  knowledge: ISgwtHelpCenterKnowledge | null;
  next: (action: ISgwtHelpCenterAction) => void;
  previous: (nb?: number) => void;
  quit: HELPCENTER_QUIT;
  reset: () => void;
  selectedRating: number;
  send: (message: IHCMessageContent, topic: string) => void;
  step: HELPCENTER_STEPS;
  user: ISgwtHelpCenterUser | null;
}

export class FeedbackForm extends React.Component<IFeedbackProps, IFeedbackState> {
  constructor(props: IFeedbackProps) {
    super(props);
    this.state = {
      btnDisabled: !this.isValidForm(props.selectedRating),
      contactMe: false,
      content: '',
      dirty: false,
      ipAddress: 'n/a',
      isValid: true,
      rating: props.selectedRating,
    };
    findIP.then((ipAddress) => this.setState({ ipAddress }));
  }

  private send = (): void => {
    const url = window.location.toString();
    const domain = url.substring(0, url.indexOf('/', 8));
    const content = `Feedback sent from ${domain}
Full URL: ${url}
Application ID: ${this.props.applicationId}
User agreed to be contacted: ${this.state.contactMe ? 'YES' : 'NO'}
Context:
${this.props.feedbackContext}

###

${this.state.content}
`;
    const message: IHCFeedbackContent = {
      ...generateMessageContent(this.props.user, content),
      ip: this.state.ipAddress,
      rating: this.state.rating,
    };

    if (isReadyToSend(message, 'feedback') && this.isValidForm(message.rating)) {
      if (!this.state.isValid) {
        this.setState({ isValid: true });
      }
      this.props.send(message, 'feedback');
    } else {
      this.setState({ isValid: false });
    }
  };

  private isValidForm(rating: number): boolean {
    return !!rating;
  }

  componentDidMount() {
    registerHelpCenterEvent('contact-us.feedback-form');
  }

  /**
   * We disable the "click out" feature (i.e. a click outside the SidePanel closes it) if the form
   * is dirty (i.e. the user started to fill it).
   */
  private handleClose = (fromClickOut: boolean) => {
    if (!fromClickOut || !this.state.dirty) {
      this.props.close();
    }
  };

  private changeContent = (e: any) => {
    const content = e.target.value;
    this.setState({
      dirty: true,
      content,
      btnDisabled: !this.isValidForm(this.state.rating),
    });
  };

  private contactMeChanged = (e: any) => {
    this.setState({
      dirty: true,
      contactMe: e.target.value,
    });
  };

  private changeRating = (rating: number) =>
    this.setState({
      dirty: true,
      rating,
      btnDisabled: !this.isValidForm(rating),
    });

  render() {
    const { isSending, translator } = this.props;
    const { btnDisabled, content, isValid, rating } = this.state;

    return (
      <SidePanel
        widget="sgwt-help-center"
        onClose={this.handleClose}
        expanded={this.props.expanded}
        title={this.props.translator.translate('panel.feedback')}
      >
        <PanelHeader {...this.props} hasSearch={false} />
        <form className="card-body px-4 pb-4 pt-0 d-flex flex-column">
          {translator.translate('feedback.text')}

          <div className="mt-3 text-secondary">{translator.translate('feedback.rating')}</div>
          <RatingSelector
            container="form"
            isValid={isValid || !!rating}
            rating={rating}
            ratingSelected={this.changeRating}
            starSize={32}
            translator={translator}
          />

          <div className="form-group my-3">
            <label htmlFor="message-description" className="font-weight-normal fw-normal">
              {translator.translate('feedback.comment')}
            </label>
            {!!rating && rating <= 3 && (
              <p className="bg-warning-alpha-md p-3 border-warning text-warning mb-2" aria-live="polite">
                <i className="icon mr-1 me-1">
                  <SvgIcon type="warning" />
                </i>
                {translator.translate('feedback.comment.lowRatingWarning')}
              </p>
            )}
            <textarea
              id="message-description"
              className={`mt-1 form-control ${!isValid && !content ? 'is-invalid' : ''}`}
              name="comment"
              value={content}
              onChange={this.changeContent}
              rows={11}
              placeholder={translator.translate('feedback.comment.placeholder')}
            />
          </div>
          <div className="form-check mb-3">
            <input type="checkbox" className="form-check-input" id="message-contact" onChange={this.contactMeChanged} />
            <label className="form-check-label" htmlFor="message-contact">
              {translator.translate('feedback.comment.contact')}
            </label>
          </div>
          <SendButton translator={translator} onSend={this.send} sending={isSending} disabled={btnDisabled} />
        </form>
      </SidePanel>
    );
  }
}
