import { Bus, setupPlatform } from '@sg-widgets/platform';

/**
 * `SGWTWidgetConfiguration` is nomally created by the `sgwt-widget-polyfill-webcomponent.js` file.
 * This function checks if the `SGWTWidgetConfiguration` exists and if the bus exists.
 * If not, it creates them.
 */
export function checkSGWTWidgetConfiguration() {
  // SGWTWidgetConfiguration does not exist at all...
  if (typeof window.SGWTWidgetConfiguration === 'undefined') {
    console.error(
      '[SGWT Widgets] SGWTWidgetConfiguration does not exist. We create it, but the SGWT Polyfills file should be loaded to avoid unexpected behaviors!',
    );
    setupPlatform();
  }
  // The bus in SGWTWidgetConfiguration does not exist...
  if (typeof window.SGWTWidgetConfiguration.bus === 'undefined') {
    console.error(
      '[SGWT Widgets] The Widgets bus has not been found. We create it, but the SGWT Polyfills file should be loaded to avoid unexpected behaviors!',
    );
    (window.SGWTWidgetConfiguration as any).bus = new Bus();
  }
}
