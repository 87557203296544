import React, { ReactNode } from 'react';
import { SidePanel } from '../../../common/components/SidePanel';
import { SvgIcon } from '../../../common/components/SvgIcon';
import { ITranslatorProps } from '../../../common/sgwt-i18n';
import { copyElementContentToClipboard } from '../../../common/sgwt-widgets-utils';
import {
  HELPCENTER_QUIT,
  HELPCENTER_STEPS,
  ISgwtHelpCenterAction,
  ISgwtHelpCenterError,
  ISgwtHelpCenterKnowledge,
  ISgwtHelpCenterMessageTopic,
  ISgwtHelpCenterUser,
} from '../sgwt-help-center.types';
import { ICmtApiResponse, IHCMessageContent, IHCMessageResponse, IUnityApiResponse } from '../shared';
import { PanelHeader } from './PanelHeader';

interface PanelInformationProps extends ITranslatorProps {
  cancelChat: () => void;
  changeExpandableState: () => void;
  chatContext?: string;
  close: () => void;
  defaultSendTo: string;
  environment: string;
  expanded: boolean;
  hasForm: boolean;
  hasMessageOnly: boolean;
  isLoading: boolean;
  isSending: boolean;
  knowledge: ISgwtHelpCenterKnowledge | null;
  messageTopics: ISgwtHelpCenterMessageTopic[] | null;
  next: (action: ISgwtHelpCenterAction) => void;
  noConsole: boolean;
  previous: (nb?: number) => void;
  quit: HELPCENTER_QUIT;
  reset: () => void;
  send: (message: IHCMessageContent, topic: string) => void;
  step: HELPCENTER_STEPS;
  topicId?: string;
  user: ISgwtHelpCenterUser | null;
}

interface MessageBodyProps extends ITranslatorProps {
  onClick: () => void;
  success: boolean;
  children?: ReactNode;
}

interface CommunicationResponsePanelProps extends PanelInformationProps {
  communicationResponse: IHCMessageResponse;
  onClick: () => void;
}

interface ErrorPanelProps extends PanelInformationProps {
  errorMessage: ISgwtHelpCenterError | null;
  onClick: () => void;
}

const MessageBody = ({ children, success, translator }: MessageBodyProps) => {
  const clazz = success ? 'success' : 'danger';
  return (
    <div className="card-body d-flex">
      <div className="align-self-center w-100">
        <i
          className={`icon bg-${clazz}-alpha-md bg-${clazz} bg-opacity-50 text-${clazz} display-3 mb-3 p-1`}
          style={{ borderRadius: '50%' }}
          aria-hidden="true"
        >
          <SvgIcon type={success ? 'check' : 'close'} className="mt-n2" width={48} height={48} />
        </i>
        <p className={`mb-0 text-${clazz} display-4`}>
          {translator.translate(success ? 'message.communication.submitted' : 'message.error')}
        </p>
        {children}
      </div>
    </div>
  );
};

export const CommunicationResponsePanel = (props: CommunicationResponsePanelProps) => {
  const { communicationResponse, onClick, translator } = props;

  let element: HTMLDivElement;
  const isFeedback = communicationResponse.type === 'feedback';
  const ticketId =
    communicationResponse.type === 'unity' || communicationResponse.type === 'impulse'
      ? (communicationResponse.reponse as IUnityApiResponse).id
      : communicationResponse.type === 'cmt'
        ? (communicationResponse.reponse as ICmtApiResponse).ID
        : null;

  const copyToClipboard = () => copyElementContentToClipboard(element);

  return (
    <SidePanel
      widget="sgwt-help-center"
      onClose={props.close}
      expanded={props.expanded}
      title={props.translator.translate('panel.contact-us.response')}
    >
      <PanelHeader {...props} hasSearch={false} />
      <MessageBody onClick={onClick} success={true} translator={translator}>
        <p className="mb-5">
          {translator.translate(
            `message.communication.message.${isFeedback ? 'feedback' : ticketId ? 'ticket' : 'mail'}`,
          )}
        </p>
        {ticketId && (
          <div>
            <div className="d-flex justify-content-between">
              <div className="pt-2">{translator.translate('message.communication.number')}</div>
              <button className="btn btn-primary-alt" onClick={copyToClipboard}>
                {translator.translate('message.communication.copy')}
              </button>
            </div>
            <div
              className="p-3 bg-lvl3 text-center display-5"
              ref={(elt: HTMLDivElement) => {
                element = elt;
              }}
            >
              {ticketId}
            </div>
          </div>
        )}
      </MessageBody>
      <div className="card-footer px-4 py-3">
        <button className="btn btn-primary btn-block btn-lg" onClick={onClick}>
          {translator.translate('message.close')}
        </button>
      </div>
    </SidePanel>
  );
};

export const ErrorPanel = (props: ErrorPanelProps) => {
  const { errorMessage, onClick, translator } = props;
  let element: HTMLDivElement;
  const copyToClipboard = () => copyElementContentToClipboard(element);

  return (
    <SidePanel
      widget="sgwt-help-center"
      onClose={props.close}
      expanded={props.expanded}
      title={props.translator.translate('panel.contact-us.error')}
    >
      <PanelHeader {...props} hasSearch={false} />
      <MessageBody onClick={onClick} success={false} translator={translator}>
        <div>{translator.translate('message.sentError.text')}</div>
        {errorMessage && errorMessage.contact && (
          <div
            dangerouslySetInnerHTML={{
              __html: translator.translate('message.sentError.contact', {
                mail: errorMessage.contact,
              }),
            }}
          />
        )}
        {errorMessage && errorMessage.technicalDetails && (
          <div>
            <div className="d-flex justify-content-between">
              <div className="pt-2 text-secondary">
                {translator.translate('message.communication.technicalDetails')}
              </div>
              <button className="btn btn-primary-alt" onClick={copyToClipboard}>
                {translator.translate('message.communication.copy')}
              </button>
            </div>
            <div
              className="p-3 bg-lvl3 text-secondary"
              ref={(elt: HTMLDivElement) => {
                element = elt;
              }}
            >
              {errorMessage.technicalDetails}
            </div>
          </div>
        )}
      </MessageBody>
      <div className="card-footer px-4 py-3">
        <button className="btn btn-primary btn-block btn-lg" onClick={onClick}>
          {translator.translate('message.close')}
        </button>
      </div>
    </SidePanel>
  );
};

export const LoadingPanel = (props: PanelInformationProps) => (
  <SidePanel
    widget="sgwt-help-center"
    onClose={props.close}
    expanded={props.expanded}
    title={props.translator.translate('panel.contact-us.loading')}
  >
    <PanelHeader {...props} hasSearch={false} />
    <div className="card-body">
      <div className="d-flex h-100">
        <div className="align-self-center w-100">
          <div className="spinner-grow blinker blinker-lg text-nowrap" role="status" style={{ fontSize: '1.5rem' }}>
            {props.translator.translate('applicationWaitingData')}
          </div>
        </div>
      </div>
    </div>
  </SidePanel>
);
