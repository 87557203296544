import React from 'react';
import { SvgIcon } from '../../../../common/components/SvgIcon';
import { registerHelpCenterEvent } from '../../../../common/monitoring';
import { ITranslatorProps } from '../../../../common/sgwt-i18n';
import { HELPCENTER_QUIT, ISgwtHelpCenterAction } from '../../sgwt-help-center.types';

interface IChatState {
  showMessage: HELPCENTER_QUIT | null;
}

interface IChatProps extends ITranslatorProps {
  chatContext?: string;
  domain?: string;
  random?: string;
  quit: HELPCENTER_QUIT;
  cancelChat: () => void;
  close: () => void;
  next: (action: ISgwtHelpCenterAction) => void;
  previous: (nb?: number) => void;
  noConsole: boolean;
}

export default class Chat extends React.Component<IChatProps, IChatState> {
  private _sgBot: HTMLElement | null = null;
  private _ref: HTMLDivElement | null = null;

  constructor(props: IChatProps) {
    super(props);
    this.state = { showMessage: null };
  }

  public componentDidMount() {
    if (this._ref) {
      registerHelpCenterEvent('contact-us.chat-form.open');
      const {
        chatContext,
        random = `sgwt-helpcenter-${new Date().valueOf()}-${Math.random().toString()}`,
        noConsole,
      } = this.props;
      this._sgBot = document.createElement('sgwt-sgbot-ms');
      if (chatContext) {
        this._sgBot.setAttribute('chat-context', chatContext);
      }
      if (noConsole) {
        this._sgBot.setAttribute('no-console', JSON.stringify(noConsole));
      }
      this._sgBot.setAttribute('random', random);
      this._ref.appendChild(this._sgBot);
    }
  }

  public componentWillReceiveProps(nextProps: IChatProps): void {
    const { showMessage } = this.state;
    if (nextProps.quit !== showMessage) {
      this.setState({ showMessage: nextProps.quit });
      if (this._ref && this._sgBot) {
        this._sgBot.style.display = nextProps.quit ? 'none' : '';
      }
    }
  }

  public componentWillUnmount() {
    if (this._ref && this._sgBot) {
      this._sgBot.remove();
      this._sgBot = null;
      registerHelpCenterEvent('contact-us.chat-form.close');
    }
  }

  public render() {
    const { cancelChat, close, previous, quit = '', translator } = this.props;
    return (
      <div
        ref={(el: HTMLDivElement) => {
          this._ref = el;
        }}
        className={`d-flex h-100 ${quit ? '' : 'flex-column'}`}
      >
        {quit ? (
          <div className="align-self-center">
            <i
              className="icon bg-warning-alpha-md text-warning display-3 mb-3 p-1"
              style={{ borderRadius: '50%' }}
              aria-hidden="true"
            >
              <SvgIcon type="warning" className="mt-n2" />
            </i>
            <p className="mb-0 text-warning display-5">{translator.translate('chatExitMessage')}</p>
            <p className="mt-4">
              <button className="btn btn-outline-primary btn-lg btn-block" type="button" onClick={cancelChat}>
                {translator.translate('chatExitCancelButton')}
              </button>
              <button
                className="btn btn-danger btn-lg btn-block mt-2"
                type="button"
                onClick={() => {
                  quit === 'close' ? close() : previous();
                }}
              >
                {translator.translate('chatExitOkButton')}
              </button>
            </p>
          </div>
        ) : (
          <div className="panel-chat-header text-secondary mb-1">{translator.translate('chatInformationMessage')}</div>
        )}
      </div>
    );
  }
}
