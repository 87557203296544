// Topics published by sgwt-connect
export const BUS_ACCESS_TOKEN = 'sg-connect.access-token';
export const BUS_USER_CONNECTION = 'sg-connect.user-connection';
export const BUS_USER_INFO = 'sg-connect.user-info';
export const BUS_GRANTED_SCOPE = 'sg-connect.granted-scope';

// Topic published by sgwt-account-center
export const BUS_GLOBAL_LANGUAGE = 'global.language';
export const BUS_SG_DESIGN_SYSTEM_THEME = 'sg-design-system.theme';

export type SubscriptionHandle = unknown;

// Payload type for BUS_USER_CONNECTION topic
export interface ISgwtUserConnectionStatus {
  connected: boolean;
  mail?: string;
  claims?: any;
}

export interface ISgConnectUserInfo {
  sub: string;
  mail: string;
  last_name: string;
  login_ad: string;
  locale: string;
  contact_id: string;
  sgconnect_id: string;
  rc_local_sigle: string;
  name: string;
  is_sg_group_user: string;
  family_name: string;
  first_name: string;
  preferred_language: string;
  origin_network: string;
  auth_level: string;
  [key: string]: any;
}
