import React from 'react';
import { SidePanel } from '../../../common/components/SidePanel';
import { ITranslatorProps } from '../../../common/sgwt-i18n';
import {
  HELPCENTER_QUIT,
  HELPCENTER_STEPS,
  HelpCenterFormInput,
  IHelpCenterRemoteConfiguration,
  ISgwtHelpCenterAction,
  ISgwtHelpCenterError,
  ISgwtHelpCenterKnowledge,
  ISgwtHelpCenterMessageTopic,
  ISgwtHelpCenterSearchResult,
  ISgwtHelpCenterUser,
} from '../sgwt-help-center.types';
import { ICmtFormParameters, IHCMessageContent, IHCMessageResponse, IUnityFormParameters } from '../shared';
import { KnowledgeBody } from './knowledge/KnowledgeBody';
import { FormPanel } from './FormPanel';
import { CommunicationResponsePanel, ErrorPanel, LoadingPanel } from './InformationPanels';
import { PanelFooter } from './PanelFooter';
import { PanelHeader } from './PanelHeader';

interface HelpCenterPanelProps extends ITranslatorProps {
  allowScreenshot: boolean;
  applicationId?: string;
  cancelChat: () => void;
  categoryId?: string;
  changeExpandableState: () => void;
  chatContext?: string;
  close: () => void;
  cmtConfiguration: ICmtFormParameters | null;
  communicationResponse: IHCMessageResponse | null;
  defaultSendTo: string;
  environment: string;
  errorMessage: ISgwtHelpCenterError | null;
  expanded: boolean;
  feedbackContext: string;
  formValues?: HelpCenterFormInput | null;
  hasForm: boolean;
  hasIntroductionTour: boolean;
  hasMessageOnly: boolean;
  helpCenterConfiguration: IHelpCenterRemoteConfiguration | null;
  unityConfiguration: IUnityFormParameters | null;
  isError: boolean;
  isLoading: boolean;
  isSending: boolean;
  knowledge: ISgwtHelpCenterKnowledge | null;
  messageTemplate: string | null;
  messageTopics: ISgwtHelpCenterMessageTopic[] | null;
  mode?: string;
  next: (action: ISgwtHelpCenterAction) => void;
  noConsole: boolean;
  noImprovement: boolean;
  previous: (nb?: number) => void;
  quit: HELPCENTER_QUIT;
  reset: () => void;
  searchResults: ISgwtHelpCenterSearchResult[] | null;
  searchValue?: string;
  selectedRating: number;
  send: (message: IHCMessageContent, topic: string) => void;
  startIntroductionTour: () => void;
  step: HELPCENTER_STEPS;
  topicId?: string;
  user: ISgwtHelpCenterUser | null;
  waitForAdditionalSearchResults: boolean;
}

const isB2B2CMode = (mode?: string) => {
  if (mode === 'b2b2c') {
    return true;
  }
  // "mode" attribute has been introduced recently on <sgwt-help-center>. So if it is not present, we need to check
  // if the widget is in B2B2C mode by checking if <sgwt-account-center> is present and if it is in B2B2C mode.
  if (!mode) {
    const widget = document.querySelector('sgwt-account-center') as HTMLElement & { mode?: string };
    return widget?.mode === 'b2b2c';
  }
  return false;
};

export const HelpCenterPanel = (props: HelpCenterPanelProps) => {
  // Loading panel
  if (props.isLoading && !props.hasMessageOnly) {
    return <LoadingPanel {...props} />;
  }

  // Error panel
  if (props.isError) {
    const ok = () => (props.hasMessageOnly ? props.close() : props.reset());
    return <ErrorPanel {...props} onClick={ok} />;
  }

  // Communication response panel
  if (props.communicationResponse !== null) {
    const ok = () => (props.hasMessageOnly ? props.close() : props.reset());
    return <CommunicationResponsePanel {...props} communicationResponse={props.communicationResponse} onClick={ok} />;
  }

  // Form panel (chatbot, mail, CMT, Impulse)
  if (
    props.step === HELPCENTER_STEPS.CHAT ||
    props.step === HELPCENTER_STEPS.FORM ||
    props.step === HELPCENTER_STEPS.FEEDBACK
  ) {
    return <FormPanel {...props} />;
  }

  const hasSearch =
    !props.isLoading &&
    (props.step === HELPCENTER_STEPS.HOME ||
      props.step === HELPCENTER_STEPS.CATEGORY ||
      props.step === HELPCENTER_STEPS.SEARCH);

  const isB2B2C = isB2B2CMode(props.mode);
  const hasFeedback = !isB2B2C && !!props.helpCenterConfiguration && !!props.helpCenterConfiguration.feedback.enabled;

  // Knowledge panel
  return (
    <SidePanel
      widget="sgwt-help-center"
      onClose={props.close}
      expanded={props.expanded}
      title={props.translator.translate('panel.help-center')}
    >
      <PanelHeader {...props} hasSearch={hasSearch} />
      <KnowledgeBody {...props} />
      <PanelFooter {...props} hasFeedback={hasFeedback} />
    </SidePanel>
  );
};
